import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Modal } from '@const/consts'
import { IModalState } from '@store/modules/modal/types'

const initialState: IModalState = {
  modal: null,
}

export const modalSlice = createSlice({
  initialState,
  name: 'modal',
  reducers: {
    setModal: (state, action: PayloadAction<Modal>) => {
      state.modal = action.payload
    },
    resetModal: (state) => {
      state.modal = null
    },
  },
})

const { actions, reducer } = modalSlice

export const { setModal, resetModal } = actions

export default reducer
