import React, { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, IconActions } from '@infologistics/frontend-libraries'

import { useAppSelector } from '@hooks'
import { getIsMobile } from '@utils/utils'
import { getDeclineTranslation, getTaskType, handleDeclineTask } from '@utils/envelope'
import { TaskType } from '@const/consts'
import { Nullable } from '@store/types'
import { IFooterProps as IProps } from './types'

import styles from './Footer.module.css'

const Footer: FC<IProps> = ({ isButtonDisabled }) => {
  const { t } = useTranslation('envelope')
  const [isDeclineBtnOpened, setIsDeclineBtnOpened] = useState(false)
  const { taskResult, taskCounter, envelope } = useAppSelector(state => state.shared)

  const renderButtons = (): Nullable<ReactElement> => {
    const type = getTaskType()

    switch (type) {
      case TaskType.SIGNING:
        return (
          <Button type='submit' theme='primary' isDisabled={isButtonDisabled} classes={styles.footer_btn}>
            {taskCounter === 0 ? t('envelope:signer.next') : t('envelope:actions.finish')}
          </Button>
        )
      case TaskType.APPROVAL:
        return (
          <Button type='submit' theme='warning' isDisabled={isButtonDisabled} classes={styles.footer_btn}>
            {t('envelope:actions.approve')}
          </Button>
        )
      case TaskType.ACQUAINTANCE:
        return (
          <Button type='submit' theme='success' isDisabled={isButtonDisabled} classes={styles.footer_btn}>
            {t('envelope:actions.acquaint')}
          </Button>
        )
      default:
        return null
    }
  }

  const renderDeclineBtn = (): ReactElement => {
    const classes = cn(
      styles.decline_btn,
      'pointer d-flex align-items-center justify-content-center',
    )

    return (
      <>
        <div className={classes} onClick={handleDeclineTask}>{getDeclineTranslation()}</div>
        <div onClick={() => setIsDeclineBtnOpened(false)} className={styles.darker}/>
      </>
    )
  }

  const renderFooterContent = (): ReactElement => {
    const classes = cn('ml-2 pointer', styles.additional_actions)
    const isDeclineAllowed = envelope.allowedActions.find(action => action.includes('DECLINE'))

    return (
      <div className={cn(styles.wrapper)}>
        {!taskResult && renderButtons()}
        {isDeclineAllowed && getIsMobile() &&
          <IconActions
            style='square'
            size='lg'
            color='primary-500'
            classes={classes}
            onClick={() => setIsDeclineBtnOpened(true)}
          />}
      </div>
    )
  }

  return (
    <footer className={cn(styles.footer, 'fixed m-0 d-flex justify-content-center align-items-center')}>
      {isDeclineBtnOpened ? renderDeclineBtn() : renderFooterContent()}
    </footer>
  )
}

export default Footer
