import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import i18n from '@app/i18n'
import { Languages } from '@const/consts'
import { getBaseUrl } from '@utils/utils'

class Http {
  private readonly axiosInstance: AxiosInstance = axios.create({
    baseURL: getBaseUrl(),
    responseType: 'json',
  })

  private readonly setHeaders = (config: AxiosRequestConfig): void => {
    const { headers = {} } = config

    config.headers = {
      ...headers,
      ['Accept-Language']: i18n.language ?? Languages.RU,
    }
  }

  async get (url: string, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    return this.axiosInstance?.get(url, config)
  }

  async post (url: string, data?: any, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    return this.axiosInstance?.post(url, data, config)
  }

  async put (url: string, data: any, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    return this.axiosInstance?.put(url, data, config)
  }

  async delete (url: string, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    return this.axiosInstance?.delete(url, config)
  }
}

export default new Http()
