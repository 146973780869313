import React, { ChangeEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setCurrentMarkKey } from '@store/modules/shared'
import { MarkType, signerFullnameRegexp, signerInitialsRegexp, signerPositionRegexp } from '@const/consts'
import { checkMarkActivity, getIsInitialsType } from '@utils/envelope'
import { IStringMarkProps as IProps } from './types'

import styles from './StringMark.module.scss'

const defaultFontSize = 12

const StringMark = (props: IProps) => {
  const {
    baseStyles,
    classes,
    isNextTaskMark,
    markKey: key,
    values,
    touched,
    width: inputWidth,
    handleBlur,
    setFieldValue,
    renderTooltip,
  } = props

  const { t } = useTranslation('envelope')
  const { isTriedSubmit, highlightedMarkKey } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const isInactive = checkMarkActivity(isNextTaskMark)
  const { marks, nextTaskMarks} = values
  const currentTaskMarks = isNextTaskMark && nextTaskMarks ? nextTaskMarks : marks

  const { fontSize, isReadOnly, isRequired, tooltip, type, value } = currentTaskMarks[key]
  const hasError = (isTriedSubmit || Object.keys(touched).includes(key)) &&
    (isRequired || type === MarkType.FULLNAME) && !value?.toString().length

  const styless = {
    ...baseStyles,
    width: `${inputWidth}px`,
    height: `${(fontSize ?? defaultFontSize) + 4}px`, /* 4 - 2 paddings of 2 px */
  }
  const inputClasses = cn(
    styles.input,
    classes,
    hasError && styles.input_error,
    highlightedMarkKey === key && styles.input_focused,
    isInactive && styles.input_inactive,
  )

  const placeholder = getIsInitialsType(type) ? t(`envelope:signer.${type.toLocaleLowerCase()}`) : ''

  const handleIninialsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const marksForIndex = isNextTaskMark ? 'nextTaskMarks' : 'marks'
    let value = e.target.value
    const regexp = {
      [MarkType.FULLNAME]: signerFullnameRegexp,
      [MarkType.INITIALS]: signerInitialsRegexp,
      [MarkType.POSITION]: signerPositionRegexp,
    }

    value = value.match(regexp[type]) ? value : value.slice(0, -1)

    if (getIsInitialsType(type)) {
      Object.keys(currentTaskMarks).forEach((key) => {
        if (currentTaskMarks[key].type === type) setFieldValue(`${marksForIndex}.${key}.value`, value)
      })
    } else {
      setFieldValue(`${marksForIndex}.${key}.value`, value)
    }
  }

  const handleClick = (_e: MouseEvent<HTMLInputElement>): void => {
    dispatch(setCurrentMarkKey(key))
  }

  return (
    <div className={cn(styles.input_wrapper, 'd-flex')}>
      <input
        className={inputClasses}
        disabled={isReadOnly || isInactive}
        name={key}
        onChange={handleIninialsChange}
        placeholder={placeholder}
        style={styless}
        value={value ? value.toString() : ''}
        onBlur={handleBlur}
        onClick={handleClick}
      />
      {renderTooltip(tooltip, key)}
      </div>
  )
}

export default StringMark
