import { combineReducers, configureStore } from '@reduxjs/toolkit'

import startupReducer from './modules/startup'
import sharedReducer from './modules/shared'
import modalReducer from './modules/modal'

export const appReducer = combineReducers({
  app: startupReducer,
  shared: sharedReducer,
  modal: modalReducer,
})

const store = configureStore({
    devTools: { trace: true },
    reducer: appReducer,
  })

export default store
