import { DocumentType } from '@const/consts'
import { getBaseUrl, getSharedLinkOguid } from '@utils/utils'

class UrlService {
  public getProtocolUrl (): string {
    return `${getBaseUrl()}shared/${getSharedLinkOguid()}/envelope/file/0/type/PROTOCOL.PDF`
  }

  public getPdfUrl (fileIndex: number, docType: DocumentType): string {
    return `${getBaseUrl()}shared/${getSharedLinkOguid()}/envelope/file/${fileIndex}/type/${docType}.PDF`
  }
}

export default new UrlService()
