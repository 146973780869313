export default {
  shared: {
    boxLogo: 'logo/boxes/{{ boxOguid }}',
    complete2Signers: 'shared/{{ sharedLinkOguid }}/complete2Signers',
    envelope: 'shared/{{ sharedLinkOguid }}/envelope',
    declineTask: 'shared/{{ sharedLinkOguid }}/declineTask',
    completeTask: 'shared/{{ sharedLinkOguid }}/completeTask',
    documentFile: 'shared/{{ sharedLinkOguid }}/envelope/file/{{ fileIndex }}/type/{{ docType }}.PDF',
    phoneConfirm: 'shared/{{ sharedLinkOguid }}/phoneCheckInit',
    selfieShelf: 'shared/{{ sharedLinkOguid }}/selfieShelf',
  },
}
