import { IGroupType, IOptionsType, IOptionType, ISelectOptionType } from './types'

export const getValue = <Option extends ISelectOptionType>(
  options: IOptionsType<Option>,
  rawValue: string | string[] | null,
): Option | null | undefined => {
  if (rawValue === null) return null

  const organizedOptions: Option[] = []

  options.forEach((option: IGroupType<Option> | (IOptionType & Option)) => {
    if ('options' in option) {
      organizedOptions.push(...option.options)
    }
    if ('value' in option) {
      organizedOptions.push(option)
    }
  })

  if (organizedOptions.length) {
    return filterOptions(organizedOptions, rawValue)
  }

  return null
}

const filterOptions = <Option extends ISelectOptionType>(
  options: Array<ISelectOptionType & Option>,
  rawValue: string[] | string,
): Option | null | undefined => {
  let filteredOptions: Option | null | undefined

  if (typeof rawValue === 'string') {
    filteredOptions = options.find((option: Option) => rawValue === option.value) ?? null
  }

  return filteredOptions
}
