import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
  Button,
  FormLabel,
  getHumanReadableTimeout,
  ILibPhoneInputChangeEvent,
  Input,
  PhoneInput,
  useStateWithCallback,
} from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { getIsMobile, getSharedLinkOguid } from '@utils/utils'
import { getIsNextTask, getNextTaskRecipient, getRecipient } from '@utils/envelope'
import { phoneConfirm, setLastPhone } from '@store/modules/shared'
import PhoneConfirmModalWrapper from './components/PhoneConfirmModalWrapper'
import { IPhoneConfirmModalProps as IProps, PhoneVerifyInput } from './types'

import styles from './PhoneConfirmModal.module.scss'

const PhoneConfirmModal: FC<IProps> = ({
  codeTimeout,
  codeValue,
  errorText,
  handleFinishSigning,
  setCodeTimeout,
  setCodeValue,
  setTranId,
}) => {
  const { t } = useTranslation('envelope')

  const dispatch = useAppDispatch()
  const { lastPhone, lastPhoneNextTask } = useAppSelector(state => state.shared)

  const isNextTask = getIsNextTask()
  const userPhone = isNextTask ? lastPhoneNextTask : lastPhone
  const signerPhone = isNextTask ? getNextTaskRecipient()?.declaredSigner.phone : getRecipient()?.declaredSigner.phone
  const [activeInput, setActiveInput] = useState<PhoneVerifyInput>(PhoneVerifyInput.PHONE)
  const [phoneNumber, setPhoneNumber] = useStateWithCallback('')

  useEffect(() => {
    if (signerPhone) setPhoneNumber(`+${signerPhone}`)
    else if (userPhone) setPhoneNumber(userPhone)
    submitPhoneNumber()
  }, [])

  const submitPhoneNumber = (): void => {
    if (signerPhone || codeTimeout) setActiveInput(PhoneVerifyInput.CODE)
    if (phoneNumber) dispatch(setLastPhone({ value: phoneNumber, isNextTask }))

    const phone = signerPhone ?? +phoneNumber.replace(/\D/g, '')

    if (phone !== 0 && !codeTimeout) {
      dispatch(phoneConfirm({ sharedLinkOguid: getSharedLinkOguid(), data: { phone }}))
        .unwrap()
        .then((response) => {
          setActiveInput(PhoneVerifyInput.CODE)
          setCodeTimeout(+(response?.expired ?? 0))
          setTranId(response?.tranId ?? '')
        })
    }
  }

  const renderFormattedCodeTimeout = (): ReactNode => {
    if (!codeTimeout || getIsActivePhone()) return null

    const timeout = getHumanReadableTimeout(codeTimeout)

    return (
      <span className='font-xs text-muted'>
        {t('envelope:phone.codeTimeoutMessage')} {timeout}
      </span>
    )
  }

  const renderNewCodeBtn = (): ReactNode => {
    if (codeTimeout || getIsActivePhone()) return null

    return (
      <Button
        linkStyle='none'
        onClick={submitPhoneNumber}
        size='small'
        theme='link'
        classes={cn(styles.newcode_btn, 'text-default')}
      >
        {t('envelope:phone.newCode')}
      </Button>
    )
  }

  const handleSubmitBtnClick = (): void => {
    getIsActivePhone() ? submitPhoneNumber() : handleFinishSigning()
  }

  const getIsActivePhone = (): boolean => activeInput === PhoneVerifyInput.PHONE

  const getIsBtnDisabled = (): boolean => getIsActivePhone()
    ? (intlTelInputUtils ? !intlTelInputUtils?.isValidNumber(phoneNumber, '') : false)
    : !codeValue.length

  const handleInputChange = (cb: (value: string) => void) =>
    (evt: ChangeEvent<HTMLInputElement> | ILibPhoneInputChangeEvent) => {
      cb(evt.target.value)
    }

  return (
    <PhoneConfirmModalWrapper >
      <p className={cn('text-muted font-default m-0 mb-4', getIsMobile() && 'text-center')}>
        {getIsActivePhone()
          ? t('envelope:phone.enterNumberMessage')
          : t('envelope:phone.enterCodeMessage', { phoneNumber })
        }
      </p>

      {getIsActivePhone() && <FormLabel htmlFor='phoneNumber'>{t('envelope:phone.label')}</FormLabel>}

      <div className={cn('d-flex', getIsMobile() && 'flex-column')}>
        <div className={styles.phone_input}>
          <PhoneInput
            value={phoneNumber}
            errorText={errorText ?? undefined}
            hasError={!!errorText}
            classes={cn(!getIsActivePhone() && 'd-none')}
            onChange={handleInputChange(setPhoneNumber)}
          />
          <Input
            name='code'
            classes={cn(getIsActivePhone() && 'd-none', styles.phone_input_code)}
            onChange={handleInputChange(setCodeValue)}
            value={codeValue}
            errorText={errorText ?? undefined}
            hasError={!!errorText}
          />
        </div>

        <button
          className={cn(styles.submit_btn, 'text-default pointer')}
          disabled={getIsBtnDisabled()}
          onClick={handleSubmitBtnClick}
        >
          {getIsActivePhone() ? t('envelope:phone.send') : t('envelope:phone.confirm')}
        </button>
      </div>
      <div className={styles.newcode_wrapper}>
        {renderFormattedCodeTimeout()}
        {renderNewCodeBtn()}
      </div>
    </PhoneConfirmModalWrapper>
  )
}

export default PhoneConfirmModal
