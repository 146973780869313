import React, { ChangeEvent, FC, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { FormikProps } from 'formik'
import cn from 'classnames'

import { Button, FormLabel, IconAddDoc } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setUploadSignature } from '@store/modules/shared'
import { displayBasicNotification } from '@utils/utils'
import { getBase64 } from '@utils/utils'
import { getIsNextTask } from '@utils/envelope'
import { imageSizeLimitBytes } from '@const/consts'
import { ISignatureValue } from '../types'

import styles from '../SignatureModal.module.scss'

const ProfileSignatureUpload: FC<FormikProps<ISignatureValue>> = ({ setFieldValue }) => {
  const { t } = useTranslation(['envelope', 'notification'])
  const { signatures, signaturesNextTask } = useAppSelector(state => state.shared)
  const isNextTask = getIsNextTask()
  const { uploadSignature, drawSignatureTrim } = isNextTask ? signaturesNextTask : signatures
  const dispatch = useAppDispatch()

  const dropZoneContainerClasses = cn(
    styles.signature_dropZone,
    'd-flex justify-content-center align-items-center flex-column flex-grow-1 relative pt-5',
  )

  useEffect(() => {
    uploadSignature && setFieldValue('newSignature', uploadSignature)
  }, [])

  const uploadFiles = (files: File[]): void => {
    if (files.length > 1) {
      displayBasicNotification({
        title: t('notification:errorTitle'),
        content: t('notification:signature:onlyOneFile'),
        type: 'error',
      })
      return
    }

    const file = files[0]
    const { name, size } = file

    if (!/\.png$/g.test(name)) {
      displayBasicNotification({
        title: t('notification:errorTitle'),
        content: t('notification:signature:invalidFileFormat'),
        type: 'error',
      })
      return
    }

    if (size > imageSizeLimitBytes) {
      displayBasicNotification({
        title: t('notification:errorTitle'),
        content: t('notification:signature:imageSizeLimit'),
        type: 'error',
      })
      return
    }

    getBase64(file)
      .then((img) => {
          const imgOptimized = img.split(';base64,')[1]
          dispatch(setUploadSignature({ value: imgOptimized, isNextTask }))
          setFieldValue('newSignature', imgOptimized)
        },
      )
  }

  const handleDrop = (dropFiles: File[]): void => {
    if (dropFiles?.[0]) {
      uploadFiles(dropFiles)
    }
  }

  const handleFilesUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target
    if (files?.[0]) {
      uploadFiles(Array.from(files))
    }
  }

  const deleteUploadedSignature = (): void => {
    dispatch(setUploadSignature({ value: null, isNextTask }))
    setFieldValue('newSignature', drawSignatureTrim)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
  })

  return (
    <div className='px-4 py-2 d-flex flex-column full-width'>
      <div className={dropZoneContainerClasses} {...getRootProps()}>
        <input {...getInputProps()} />
        {
          !uploadSignature ?
            <Fragment>
              <IconAddDoc classes={cn(styles.signature_upload_image, 'mb-4')} color='gray-300'/>
              <FormLabel
                className='fl-button fl-button-base fl-button-default text-color-important'
                text={t('envelope:signature.uploadFile')}
              >
                <input type='file' className='d-none' onChange={handleFilesUpload} accept='image/png'/>
              </FormLabel>
            </Fragment> :
            <Fragment>
              <img
                src={`data:image/png;base64, ${uploadSignature}`}
                alt={t('envelope:signature.signatureImage')}
                className={styles.signature_image}
              />
              <div className={cn(styles.signature_actionButtons, 'absolute d-flex align-items-baseline at-0')}>
                <FormLabel className='fl-button fl-button-base fl-button-link text-primary mr-4'>
                  <span className='fl-button_text'>
                    { t('envelope:signature.uploadNewFile') }
                  </span>
                  <input type='file' className='d-none' onChange={handleFilesUpload} accept='image/png'/>
                </FormLabel>
                <Button theme='link' linkStyle='none' onClick={deleteUploadedSignature}>
                  {t('envelope:signature.delete')}
                </Button>
              </div>
            </Fragment>
        }
      </div>
      <p className='text-center text-muted mt-2 mb-0'>
        { t('envelope:signature.formatFile') }
      </p>
    </div>
  )
}

export default ProfileSignatureUpload
