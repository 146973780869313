import React, { ReactElement, FC } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { DropdownButton, ILibHorizontalPosition, ILibDropdownItem } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setIsOpenedMenu, setIsOpenedEnvelopeInfo } from '@store/modules/startup'
import {  getIsMobile } from '@utils/utils'
import { getDeclineTranslation, getTaskType, handleDeclineTask } from '@utils/envelope'
import { MenuActions, TaskType } from '@const/consts'

import styles from '../../HeaderWrapper.module.css'
import LanguageSelectorWrapper from '../LanguageSelectorWrapper'
import DownloadButton from '../DownloadButton'

const EnvelopeActionsButton: FC = (): ReactElement => {
  const { t } = useTranslation('common')
  const { isOpenedMenu, isOpenedEnvelopeInfo } = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const getActions = (): ILibDropdownItem[] => {
    if (getIsMobile()) {
      return [
        {
          name: MenuActions.VIEW_LOG,
          translation: t('envelope:actions.log'),
        },
        {
          name: MenuActions.DOWNLOAD_DOC,
          element: <DownloadButton/>,
        },
        {
          name: MenuActions.CHANGE_LNG,
          element: renderLanguageElement(),
        },
      ]
    }
    else if (getTaskType() === TaskType.SIGNING || getTaskType() === TaskType.APPROVAL) {
      return [{
        name: MenuActions.DECLINE,
        translation: getDeclineTranslation(),
      }]
    }

    return []
  }

  const renderLanguageElement = (): ReactElement => (
    <div className='d-flex align-items-center'>
      <p className='m-0 mr-4'>{t('common:language')}</p>
      <LanguageSelectorWrapper/>
    </div>
  )

  const handleClickActionBtn = ({ name }: ILibDropdownItem) => {
    if (name === MenuActions.DECLINE) {
      handleDeclineTask()
    }
    else if (name === MenuActions.VIEW_LOG) {
      dispatch(setIsOpenedEnvelopeInfo(!isOpenedEnvelopeInfo))
    }
  }

  const renderMenuItems = (item: ILibDropdownItem): ReactElement => {
    const { name, translation, element } = item

    return (
      <div
        key={name}
        className={cn(styles.actions_menu_item, name !== MenuActions.DOWNLOAD_DOC && 'p-4')}
        onClick={() => handleMenuItemClick(item)}
      >
        {element ?? translation}
      </div>
    )
  }

  const handleMenuItemClick = (item: ILibDropdownItem):void => {
    if (!item.element) {
      dispatch(setIsOpenedMenu(false))
      handleClickActionBtn(item)
    }
  }

  return (!isOpenedMenu && getActions().length
    ? <div className={cn(styles.actions_buttons, 'mr-n4')}>
        <DropdownButton
          popoverClasses='mr-6'
          itemClasses={cn(styles.actions_buttons_item, 'py-2')}
          dropdownItems={getActions()}
          onSelect={handleClickActionBtn}
          selectedTitle={t('envelope:action')}
          withoutArrow
          horizontalPosition={ILibHorizontalPosition.RIGHT}
        />
      </div>
    : <>{getActions().map(renderMenuItems)}</>
  )
}

export default EnvelopeActionsButton
