import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IStartupState } from './types'

const initialState: IStartupState = {
  isLoaded: false,
  isOpenedEnvelopeInfo: false,
  isOpenedMenu: false,
  isShownFinalMessage: false,
}

export const startupSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload
    },
    setIsOpenedMenu: (state, action: PayloadAction<boolean>) => {
      state.isOpenedMenu = action.payload
    },
    setIsShownFinalMessage: (state, action: PayloadAction<boolean>) => {
      state.isShownFinalMessage = action.payload
    },
    setIsOpenedEnvelopeInfo: (state, action: PayloadAction<boolean>) => {
      state.isOpenedEnvelopeInfo = action.payload
    },
  },
})

export const {
  setIsLoaded,
  setIsOpenedMenu,
  setIsShownFinalMessage,
  setIsOpenedEnvelopeInfo,
}  = startupSlice.actions

export default startupSlice.reducer
