import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, IconInfo } from '@infologistics/frontend-libraries'

import { useAppSelector, useAppDispatch } from '@hooks'
import { setIsOpenedMenu, setIsOpenedEnvelopeInfo } from '@store/modules/startup'
import { getIsMobile } from '@utils/utils'
import { getIsDoubleSigning, getPointerDisplay } from '@utils/envelope'
import DownloadButton from './components/DownloadButton'
import EnvelopeActionsButton from './components/EnvelopeActionsButton'
import LanguageSelectorWrapper from './components/LanguageSelectorWrapper'
import Logo from './components/LogoWrapper'
import PointerButton from './components/PointerButton'
import ScaleTools from './components/ScaleTools'
import SignersInfo from './components/SignersInfo'

import styles from './HeaderWrapper.module.css'

const HeaderWrapper: FC = () => {
  const { t } = useTranslation()
  const { isOpenedEnvelopeInfo, isOpenedMenu, isShownFinalMessage } = useAppSelector(state => state.app)
  const { taskResult } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()
  const isMobile = getIsMobile()

  const toggleMenu = (): void => {
    dispatch(setIsOpenedMenu(!isOpenedMenu))
    dispatch(setIsOpenedEnvelopeInfo(false))
  }

  const renderHamburger = (): ReactNode => (
    <div className={cn(styles.hamburger_wrapper)} onClick={toggleMenu}>
      <div className={cn(styles.hamburger_item, isOpenedMenu && styles.active)}/>
    </div>
  )

  const toggleSidebar = () => dispatch(setIsOpenedEnvelopeInfo(!isOpenedEnvelopeInfo))

  return (
    <div className={styles.header_wrapper}>
      <div className={cn(styles.header, 'fixed py-2 px-4')}>
        <div className='full-width mx-auto d-flex align-items-center'>
          {renderHamburger()}
          <Logo />
          {!isMobile && (
            <>
              <LanguageSelectorWrapper/>
              <ScaleTools/>
            </>
          )}
          {isMobile && getPointerDisplay(!!taskResult) && <PointerButton/>}
          {!isMobile && <DownloadButton/>}
          {!isOpenedMenu && !taskResult && <EnvelopeActionsButton/>}
          {!isMobile && !isShownFinalMessage && <Button
            classes={cn(styles.info_btn, isOpenedEnvelopeInfo && styles.info_btn_active)}
            onClick={toggleSidebar}
            title={t('envelope:info.title')}
          >
            <IconInfo/>
          </Button>}
        </div>
        {getIsDoubleSigning() && !taskResult && <SignersInfo/>}
        {isMobile && <ScaleTools/>}
      </div>
    </div>
  )
}

export default HeaderWrapper
