import React, { FC, useRef } from 'react'

import { useAppSelector } from '@hooks'
import FileViewer from './components/FileViewer'
import WebcamScreen from './components/WebcamScreen'

import styles from './Envelope.module.css'

const Envelope: FC = () => {
  const { isCamOpened } = useAppSelector(state=>state.shared)
  const envelopeWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <div className={styles.envelope_wrapper} ref={envelopeWrapperRef}>
      <FileViewer envelopeWrapperRef={envelopeWrapperRef}/>
      {isCamOpened && <WebcamScreen/>}
    </div>
  )
}

export default Envelope
