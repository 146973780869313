import React, { ReactElement, FC, useEffect, useState, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Ellipsis } from '@infologistics/frontend-libraries'

import { useAppSelector } from '@hooks'
import { getIsMobile } from '@utils/utils'
import { getNextTaskRecipient, getRecipient } from '@utils/envelope'

import styles from './SignersInfo.module.scss'

const SignersInfo: FC = (): ReactElement => {
  const { t } = useTranslation('envelope')
  const { taskCounter, envelope } = useAppSelector(state => state.shared)
  const [signers, setSigners] = useState<Array<string | null>>([])

  useEffect(() => {
    const sessionSigners = []

    sessionSigners.push(getRecipient()?.declaredSigner.fullName ?? null)
    sessionSigners.push(getNextTaskRecipient()?.declaredSigner.fullName ?? null)

    setSigners(sessionSigners)
  },[envelope])

  const renderSigner = (name: string | null, index: number): ReactNode => {
    const isActiveSigner = index === taskCounter

    const signerClasses = cn(
      styles.signer,
      isActiveSigner && styles.signer_active,
      'd-flex align-items-center',
    )

    return (
      <div className={signerClasses} key={index}>
        <div className='d-flex align-items-center'>
          <div className={cn(styles.index_label, 'mr-2 fw-600')}>{index + 1}</div>
          {name ? (
            <div>
              {!getIsMobile() && <p className='m-0 font-xs text-muted'>{t('envelope:signer.title')}</p>}
              <p className={cn(styles.name, 'm-0')} title={name}>
                <Ellipsis>{name}</Ellipsis>
              </p>
            </div>
          ) : (
          <p className={cn(styles.name, 'm-0')}>
            {`${t('envelope:signer.title')} ${index + 1}`}
          </p>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.wrapper, 'd-flex justify-content-center')}>
      {signers.map(renderSigner)}
    </div>
  )
}

export default SignersInfo
