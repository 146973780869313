import { AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'
import { IEnvelope, IPhoneConfirmInit, IPhoneConfirmResponseData } from '@store/modules/shared/types'
import urls from '@const/urls'
import { ICompleteTaskData } from '@views/Envelope/components/FileViewer/types'

class SharedService extends AbstractHttpService {
  private readonly url = urls.shared

  async getEnvelope (sharedOguid: string): Promise<AxiosResponse<IEnvelope>> {
    const url = this.url.envelope.replace('{{ sharedLinkOguid }}', sharedOguid)

    return await this._http.get(url)
  }

  async declineTask (sharedOguid: string): Promise<AxiosResponse> {
    const url = this.url.declineTask.replace('{{ sharedLinkOguid }}', sharedOguid)

    return await this._http.post(url, {})
  }

  async completeTask (sharedOguid: string, data: ICompleteTaskData): Promise<AxiosResponse> {
    const url = this.url.completeTask.replace('{{ sharedLinkOguid }}', sharedOguid)

    return await this._http.post(url, data)
  }

  async complete2Signers (sharedOguid: string, data: ICompleteTaskData): Promise<AxiosResponse> {
    const url = this.url.complete2Signers.replace('{{ sharedLinkOguid }}', sharedOguid)

    return await this._http.post(url, data)
  }

  async getDocumentFile (sharedOguid: string, fileIndex: string, docType: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.documentFile
      .replace('{{ sharedLinkOguid }}', sharedOguid)
      .replace('{{ fileIndex }}', fileIndex)
      .replace('{{ docType }}', docType)

    return await this._http.get(url, {
      responseType: 'blob',
    })
  }

  async phoneConfirm (sharedOguid: string, data: IPhoneConfirmInit): Promise<AxiosResponse<IPhoneConfirmResponseData>> {
    const url = this.url.phoneConfirm.replace('{{ sharedLinkOguid }}', sharedOguid)

    return await this._http.post(url, data)
  }

  async selfieShelf (sharedOguid: string, data: FormData): Promise<AxiosResponse<string>> {
    const url = this.url.selfieShelf.replace('{{ sharedLinkOguid }}', sharedOguid)

    return await this._http.post(url, data)
  }

  async getBoxLogo (boxOguid: string): Promise<AxiosResponse<string>> {
    const url = this.url.boxLogo.replace('{{ boxOguid }}', boxOguid)

    return await this._http.get(url, {responseType: 'blob'})
  }
}

export default new SharedService()
