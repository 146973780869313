import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IconCheck, IconTimes } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { getEnvelope } from '@store/modules/shared'
import { setIsShownFinalMessage } from '@store/modules/startup'
import { TaskResult } from '@const/consts'
import { getSharedLinkOguid } from '@utils/utils'
import { getTaskType } from '@utils/envelope'
import Logo from '@common/HeaderWrapper/components/LogoWrapper'

import styles from './CompleteTask.module.scss'

const CompleteTask: FC = () => {
  const { t } = useTranslation(['envelope', 'common'])
  const { taskResult } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const centerClasses = 'd-flex flex-column align-items-center'

  const backToDoc = (): void => {
    const sharedLinkOguid = getSharedLinkOguid()
    dispatch(getEnvelope(sharedLinkOguid))
      .then(() => {
        dispatch(setIsShownFinalMessage(false))
      })
  }

  return (
    <div className={cn(styles.complete_message_wrapper, centerClasses)}>
      <Logo classes={styles.logo} customClasses={styles.logo_custom}/>
      <div className={cn(styles.complete_message_inner, centerClasses)}>
        <div className={cn(styles.icon_wrapper, centerClasses, taskResult === TaskResult.DECLINE && styles.icon_wrapper_decline)}>
          {taskResult === TaskResult.DECLINE ?
            <IconTimes size='md' color='light'/>
            : <IconCheck size='md' color='light'/>
          }
        </div>
        <p className={cn(styles.result_message, 'm-0 mt-4 fw-300')}>
          {t(`envelope:${taskResult?.toLowerCase()}Task.${getTaskType()?.toLocaleLowerCase()}`)}
        </p>
        {taskResult !== TaskResult.DECLINE && (
          <p className={'m-0 mt-4 fw-300 font-default text-center'}>
            {t('envelope:copyMessage')}
          </p>
        )}
        <button className={cn(styles.back_btn, 'mt-5 pointer')} onClick={backToDoc}>
          {t('common:back')}
        </button>
      </div>
    </div>
  )
}

export default CompleteTask
