import React, { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Popover, IconDownload } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setCurrentMarkKey, setSignatureMarks } from '@store/modules/shared'
import { setModal } from '@store/modules/modal'
import { Nullable } from '@store/types'
import { Modal } from '@const/consts'
import { getPrepMarks } from '@utils/utils'
import { checkMarkActivity, getIsNextTask } from '@utils/envelope'
import { ISignatureMarkProps as IProps } from './types'

import styles from './SignatureMark.module.scss'

const signatureRatio = 0.0351
const defaultSignatureHeight = 66

const SignatureMark: FC<IProps> = ({
  signatureKey,
  tooltip,
  signatureScale,
  pageSizes,
  setCurrentSignature,
  renderTooltip,
  setFieldValue,
  isNextTaskMark,
}) => {
  const { t } = useTranslation('envelope')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const { envelope, signatureMarks, highlightedMarkKey, signatures, signaturesNextTask, signatureMarksNextTask } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const { newSignature } = isNextTaskMark ? signaturesNextTask : signatures
  const isNextTask = getIsNextTask()
  const currentTaskSignatures = isNextTask ? signatureMarksNextTask : signatureMarks
  const isInactive = checkMarkActivity(isNextTaskMark)

  const togglePopover = (): void => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const closePopover = (): void => {
    setIsPopoverOpen(false)
  }

  const renderSignatureMark = (key: string, tooltip: Nullable<string>, signatureScale: Nullable<number>): ReactElement => {
    const markSize = pageSizes.height * signatureRatio * (signatureScale ?? 1)

    const signatureStyle = {
      height: `${markSize}px`,
      width: `${markSize}px`,
    }

    const signatureContentClasses = cn(
      styles.signature_content_wrapper,
      'd-flex flex-column align-items-center',
    )

    const signatureContentStyle = {
      transform: `scale(${markSize / defaultSignatureHeight})`,
    }

    const classes = cn(
      styles.signature,
      styles.signature_mark,
      highlightedMarkKey === key && styles.signature_mark_focused,
      isInactive && styles.signature_mark_inactive,
    )

    return (
      <div className={classes} style={signatureStyle} onClick={() => handleClickSignatureMark(key)}>
        <div className={styles.signature_inner}>
          <div className={signatureContentClasses} style={signatureContentStyle}>
            <p className='p-0 font-default m-2'>{t('envelope:signature.title')}</p>
            <IconDownload color='primary-500' size='md'/>
          </div>
          {renderTooltip(tooltip, key)}
        </div>
      </div>
    )
  }

  const renderPopoverButton = (signatureScale: Nullable<number>, tooltip: Nullable<string>, key: string): ReactElement => {
    const imgStyles = {
      transform: `scale(${signatureScale ?? 1})`,
      transformOrigin: '0 0',
    }

    if (signatureScale && signatureScale > 1) {
      imgStyles['marginBottom'] = `${(signatureScale - 1) * 100}%`
    }

    const signatureWidth = pageSizes.width - pageSizes.width * 0.01 * (getPrepMarks(envelope).find(mark => mark.key === signatureKey)?.topLeftX ?? 0)
    const signatureImgStyles = {
      height: `${pageSizes.height * signatureRatio * (signatureScale ?? 1)}px`,
      maxWidth: signatureWidth,
    }

    return (
      <div style={signatureImgStyles} className={styles.signature_img}>
        <img
          style={imgStyles}
          height={pageSizes.height * signatureRatio}
          onClick={togglePopover}
          src={`data:image/png;base64, ${newSignature}`}
          alt={t('profile:signature:signatureImage')}
        />
        {renderTooltip(tooltip, key)}
      </div>
    )
  }

  const renderSignatureImg = (key: string, tooltip: Nullable<string>, signatureScale: Nullable<number>): ReactElement => {
    const actionsClasses = cn(styles.item_action, 'p-2 font-sm')

    return (
      <div className={cn(styles.signature, isInactive && styles.signature_inactive)}>
        <Popover
          buttonComponent={renderPopoverButton(signatureScale, tooltip, key)}
          withoutArrow
          parentManagement={{
            isOpenFromParent: isPopoverOpen && !isInactive,
            callbackForClose: closePopover,
          }}
          >
          <div onClick={() => handleEditSignature(key)} className={actionsClasses}>{t('common:edit')}</div>
          <div onClick={() => handleDeleteSignature(key)} className={actionsClasses}>{t('common:delete')}</div>
        </Popover>
      </div>
    )
  }

  const handleEditSignature = (key: string): void => {
    setCurrentSignature(key)
    dispatch(setModal(Modal.SIGNATURE_IMAGE_DRAW))
    closePopover()
  }

  const handleDeleteSignature = (key: string): void => {
    dispatch(setSignatureMarks({ signatureMarks: {...currentTaskSignatures, [key]: false}, isNextTask }))
    closePopover()
    setFieldValue(`${isNextTaskMark ? 'nextTaskMarks' : 'marks'}.${key}.value`, null)
  }

  const handleClickSignatureMark = (key: string): void => {
    if (isInactive) return
    if (!newSignature) dispatch(setModal(Modal.SIGNATURE_IMAGE_DRAW))
    else {
      dispatch(setSignatureMarks({ signatureMarks: {...currentTaskSignatures, [key]: true}, isNextTask }))
      setFieldValue(`${isNextTaskMark ? 'nextTaskMarks' : 'marks'}.${key}.value`, newSignature)
    }
    setCurrentSignature(key)
    dispatch(setCurrentMarkKey(key))
  }

  return (
    newSignature && (isNextTaskMark ? signatureMarksNextTask?.[signatureKey] : signatureMarks?.[signatureKey])
    ? renderSignatureImg(signatureKey, tooltip, signatureScale)
    : renderSignatureMark(signatureKey, tooltip, signatureScale)
  )
}

export default SignatureMark
