import React, { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { getIsMobile } from '@utils/utils'

import { IModalWrapperProps as IProps } from '../types'

const PhoneConfirmWrapper: FC<IProps> = ({ children }) => {
  const { t } = useTranslation(['envelope'])

  return (
    <Fragment>
      <header className={cn('p-4 modal-header', getIsMobile() && 'text-center')}>
        <h4 className='mb-0'>
          { t('envelope:phone.title') }
        </h4>
      </header>
      <div className='p-4 pb-5 font-xs modal-body'>
        { children }
      </div>
    </Fragment>
  )
}

export default PhoneConfirmWrapper
