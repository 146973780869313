import React, { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { Modal, TIMEOUT } from '@const/consts'
import { getSharedLinkOguid } from '@utils/utils'
import { getIsDoubleSigning, getIsNextTask, getNextTaskRecipient, getRecipient } from '@utils/envelope'
import { selfieShelf, setIsCamOpened, setSelfieGuid } from '@store/modules/shared'
import { resetModal, setModal } from '@store/modules/modal'
import { ISelfieConfirmModalWrapperProps as IProps } from '../types'

import styles from '../SelfieConfirmModal.module.scss'

const SelfieConfirmModalWrapper: FC<IProps> = ({ children, handleFinishSigning, setErrorText }) => {
  const { t } = useTranslation('envelope')
  const dispatch = useAppDispatch()
  const { selfieImg, selfieGuid, selfieGuidNextTask, selfieImgNextTask } = useAppSelector(state => state.shared)
  const [isPending, setIsPending] = useState(false)
  const isNextTask = getIsNextTask()
  const img = isNextTask ? selfieImgNextTask : selfieImg

  useEffect(() => {
    const isFinishFirstSigner = selfieGuid && !getRecipient()?.isRequireSms
    const isFinishSecondSigner = selfieGuidNextTask && !getNextTaskRecipient()?.isRequireSms
    if (!getIsDoubleSigning() && isFinishFirstSigner ||
    getIsDoubleSigning() && (!isNextTask && isFinishFirstSigner || isNextTask && isFinishSecondSigner)) {
      dispatch(resetModal())
      handleFinishSigning()
    }
  }, [selfieGuid, selfieGuidNextTask])

  const takePhoto = (): void => {
    dispatch(setIsCamOpened(true))
    dispatch(resetModal())
  }

  const confirmPhoto = async (): Promise<void> => {
    if (!img) return

    setIsPending(true)
    const data = new FormData()

    const selfieBase64 = await fetch(img)
    const blob = await selfieBase64.blob()

    const splitDataURI = img.split(',')
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const file = new File([blob], `Filename.${mimeString.split('/')[1]}`, { type: mimeString} )

    data.append('file', file)

    await uploadSelfieShelf(data)
  }

  const uploadSelfieShelf = (data: FormData): void => {
    dispatch(selfieShelf({sharedLinkOguid: getSharedLinkOguid(), data}))
      .unwrap()
      .then((res) => {
        if (getRecipient()?.isRequireSms) {
          dispatch(resetModal())
          setTimeout(() => {
            dispatch(setModal(Modal.PHONE_CONFIRM))
          }, TIMEOUT + 100)
          /* modal closing animation time (TIMEOUT) + 100ms for modal changing visual effect */
        }
        dispatch(setSelfieGuid({ value: res ?? null, isNextTask }))
      })
      .catch((err) => setErrorText(err.message))
      .finally(() => setIsPending(false))
  }

  return (
    <Fragment>
      <header className='p-4 modal-header'>
        <h4 className='mb-0'>
          { t('envelope:selfie.message') }
        </h4>
      </header>
      <div className='p-4 font-xs modal-body'>
        { children }
      </div>
      <footer className='my-0 p-4 modal-footer'>
        <div className={styles.modal_btns_wrapper}>
          <Button theme='primary' onClick={takePhoto}>
            {img ? t('envelope:selfie.takeAnotherPhoto') : t('envelope:selfie.takePhoto')}
          </Button>
          {img && <Button loading={isPending} classes={styles.modal_btns_success} onClick={confirmPhoto}>
            {t('envelope:selfie.confirm')}
          </Button>}
        </div>
      </footer>
    </Fragment>
  )
}

export default SelfieConfirmModalWrapper
