import React, { ChangeEvent, FC, FocusEvent, MouseEvent, useRef } from 'react'
import cn from 'classnames'

import { useAppSelector, useAppDispatch } from '@hooks'
import { setCurrentMarkKey } from '@store/modules/shared'
import { checkMarkActivity } from '@utils/envelope'
import { ITextMarkProps as IProps } from './types'

import styles from './TextMark.module.scss'

const TextMark: FC<IProps> = ({
  baseStyles,
  classes,
  height,
  markKey: key,
  touched,
  values,
  width,
  handleBlur,
  renderTooltip,
  setFieldValue,
  isNextTaskMark,
}) => {
  const { isTriedSubmit, highlightedMarkKey } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { marks, nextTaskMarks } = values
  const currentTaskMarks = isNextTaskMark && nextTaskMarks ? nextTaskMarks : marks
  const isInactive = checkMarkActivity(isNextTaskMark)
  const { isReadOnly, isRequired, tooltip, value } = currentTaskMarks[key]

  const textStyle = {
    width: width + 'px',
    height: height + 'px',
  }

  const hasError = (isTriedSubmit || Object.keys(touched).includes(key)) &&
    isRequired && !value?.toString().length

  const textMarkClasses = cn(
    styles.text_mark,
    classes,
    hasError && styles.text_mark_error,
    highlightedMarkKey === key && styles.text_mark_focused,
    isInactive && styles.text_mark_inactive,
  )

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>, key: string): void => {
    setFieldValue(`${isNextTaskMark ? 'nextTaskMarks' : 'marks'}.${key}.value`, e.target.value)
  }

  const handleClick = (_e: MouseEvent<HTMLTextAreaElement>): void => {
    dispatch(setCurrentMarkKey(key))
  }

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>): void => {
    textareaRef.current?.scrollTo({left: 0})
    handleBlur(e)
  }

  return (
    <div>
      <textarea
        style={Object.assign(baseStyles, textStyle)}
        className={textMarkClasses}
        name={key}
        value={value?.toString() ?? ''}
        onChange={(e) => handleChangeText(e, key)}
        disabled={isReadOnly || isInactive}
        onBlur={handleFocus}
        onClick={handleClick}
        ref={textareaRef}
      />
      {renderTooltip(tooltip, key)}
    </div>
  )
}

export default TextMark
