import i18n from 'i18next'

import { ILibSelectValueType } from '@infologistics/frontend-libraries'

import { MarkType, State, TaskType } from '@const/consts'
import store from '@store/configureStore'
import { IPrepositionMark, IPrepositionMarks, IRecipient, ITask } from '@store/modules/shared/types'
import { setIsShownFinalMessage } from '@store/modules/startup'
import { declineTask, setTaskDecline } from '@store/modules/shared'
import { Nullable } from '@store/types'
import LocalStorageService from '@services/localStorage'
import { displayErrorNotification, getSharedLinkOguid } from './utils'
import { SelectChangeFunction } from './types'

export const checkMarkActivity = (isNextTaskMark: boolean): boolean => {
  return isNextTaskMark && !getIsNextTask() || !isNextTaskMark && getIsNextTask()
}

export const checkMarkFullness = (mark: IPrepositionMark): boolean => {
  const { type, value } = mark

  if (type === MarkType.CHECKBOX) return getCheckboxValue(value) ?? false
  if (type === MarkType.SIGNATURE || type === MarkType.SELECT) return !!value
  return !!value?.toString().length
}

export const checkSubmitAvailable = (marks: IPrepositionMarks): boolean => {
  return Object.keys(marks).every(key => {
    const mark = marks[key]

    const isEmptyCheckbox = mark.type === MarkType.CHECKBOX && !getCheckboxValue(mark.value)
    const isEmptyRequired = mark.isRequired && (!mark.value || typeof mark.value === 'string' && !mark.value?.length || isEmptyCheckbox)
    const isEmptySignature = mark.type === MarkType.SIGNATURE && !mark.value
    const isEmptyFullname = mark.type === MarkType.FULLNAME && typeof mark.value === 'string' && !mark.value?.length

    return !(isEmptyRequired || isEmptySignature || isEmptyFullname)
  })
}

export const getCheckboxValue = (value: Nullable<string | boolean>): Nullable<boolean> => {
  if (value === null) return null

  return value === '1' || value === true
}

export const getDeclineTranslation = (): string => {
  const { language, getResource } = i18n
  const taskType = getTaskType()

  switch (taskType) {
    case TaskType.SIGNING:
      return String(getResource(language, 'envelope', 'actions.declineSigning'))
    case TaskType.APPROVAL:
      return String(getResource(language, 'envelope', 'actions.declineApproval'))
  }

  return ''
}

export const getFilename = (fileIndex: number): string => {
  const { envelope } = store.getState().shared

  const files = envelope.files
  const filename = files.find(file => file.index === fileIndex)?.filename

  return filename ?? ''
}

export const getIsDoubleSigning = (): boolean => {
  const { envelope } = store.getState().shared

  return envelope.isNextStageSigningRequired
}

export const getIsInitialsType = (type: MarkType): boolean => {
  const initialsTypes = [MarkType.INITIALS, MarkType.FULLNAME, MarkType.POSITION]

  return initialsTypes.includes(type)
}

export const getMarkByType = (marks: IPrepositionMarks, type: MarkType): string | undefined => {
  let markKey: string | undefined = undefined

  Object.keys(marks).every(key => {
    const condition = marks[key].type === type
    if (condition) markKey = key

    return !condition
  })

  return markKey
}

export const getNextTask = (): Nullable<ITask> => {
  const { envelope } = store.getState().shared

  const flowIndex = envelope.flow.findIndex(flow => flow.state === State.IN_PROGRESS)
  const task = envelope.flow[flowIndex+1]?.tasks[0]

  return task ?? null
}

export const getNextTaskRecipient = (): Nullable<IRecipient> =>
  getNextTask()?.recipient ?? null

export const getPointerDisplay = (isTaskCompleted: boolean): boolean => {
  const { envelope } = store.getState().shared

  const isActions = envelope.allowedActions.length
  const existPrepMarks = getRecipient()?.prepositionedMarks.length

  return !!(existPrepMarks && !isTaskCompleted && isActions)
}

export const getRecipient = (): Nullable<IRecipient> =>
  getTask()?.recipient ?? null

export const getTask = (): Nullable<ITask> => {
  const { envelope } = store.getState().shared

  const flow = envelope.flow.find(flow => flow.state === State.IN_PROGRESS)
  const task = flow?.tasks.find(task => task.oguid === envelope?.boxTaskOguid)

  return task ?? null
}

export const getIsNextTask = (): boolean => {
  const { taskCounter } = store.getState().shared

  return taskCounter === 1
}

export const getTaskType = (): Nullable<TaskType> =>
  getTask()?.type ?? null

export const selectChangeCheck = (cb: any, isCheckOption = true) =>
  (option: ILibSelectValueType): SelectChangeFunction | undefined => {
    if (!isCheckOption && !Array.isArray(option)) {
      return cb(option)
    }

    if (option && !Array.isArray(option)) {
      return cb(option)
    }

    return undefined
  }

export const handleDeclineTask = () => {
  const dispatch = store.dispatch
  const sharedOguid = getSharedLinkOguid()

  dispatch(declineTask(sharedOguid))
    .unwrap()
    .then(() => {
      const redirectUrl = LocalStorageService.getRedirectUrl()

      if (redirectUrl) window.location.href = redirectUrl
      else {
        dispatch(setTaskDecline())
        dispatch(setIsShownFinalMessage(true))
      }
    })
    .catch(displayErrorNotification)
}