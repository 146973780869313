import React, { FC } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import cn from 'classnames'

import { Spinner } from '@infologistics/frontend-libraries'

import styles from './Loader.module.css'

const Loader: FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className={cn(styles.loader, 'mx-auto', 'd-flex', 'flex-column', 'align-items-center')}>
      <div className={cn(styles.spinner, 'mb-4')}>
        <Spinner />
      </div>
      <p className='h3 text-muted'>
        {t('common:loading')}
      </p>
    </div>
  )
}

export default withTranslation()(Loader)
