import AbstractLocalStorageService from './localStorageHelper/abstractLocalStorage'
import { LocalStorage, UrlParams } from '@const/consts'
import { Nullable } from '@store/types'

class LocalStorageService extends AbstractLocalStorageService {
  public getRedirectUrl (): Nullable<string> {
    return this._localStorage.getItem(LocalStorage.REDIRECT_TO_URL)
  }

  public setRedirectUrl (): void {
    const paramsString = window.location.search
    const searchParams = new URLSearchParams(paramsString)
    const redirectTo = searchParams.get(UrlParams.REDIRECT_TO)

    if (redirectTo) this._localStorage.setItem(LocalStorage.REDIRECT_TO_URL, redirectTo)
    else this._localStorage.removeItem(LocalStorage.REDIRECT_TO_URL)
  }

  public setLanguage (language: string): void {
    this._localStorage.setItem(LocalStorage.LANGUAGE, language)
  }

  public getLanguage (): void {
    this._localStorage.getItem(LocalStorage.LANGUAGE)
  }
}

export default new LocalStorageService()
