import React, { FC, ReactElement } from 'react'
import cn from 'classnames'

import { Spinner } from '@infologistics/frontend-libraries'

import styles from './SpinnerWrapper.module.css'

const SpinnerWrapper: FC = (): ReactElement => (
  <div className={cn('absolute', styles.spinner)}>
    <Spinner />
  </div>
)

export default SpinnerWrapper
