import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import fileDownload from 'js-file-download'

import SharedService from '@services/shared'
import { Nullable } from '@store/types'
import { catchAxiosError, getMarkKeysArray } from '@utils/utils'
import { FlowState, TaskResult } from '@const/consts'
import { ICompleteTask } from '@views/Envelope/components/FileViewer/types'
import {
  IFileDownload,
  IPhoneConfirmData,
  IValueFromModeData,
  ISelfieShelfData,
  ISharedState,
  ISignatureMarksData,
} from './types'

const initialState: ISharedState = {
  envelope: {
    oguid: '',
    allowedActions: [],
    files: [],
    boxTaskOguid: '',
    flow: [],
    flowState: FlowState.IN_PROGRESS,
    flowStartDateTime: '',
    isNextStageSigningRequired: false,
    sender: {
      boxEmail: '',
      boxOguid: '',
      user: {
        oguid: '',
        position: null,
        email: '',
        fullName: '',
      },
    },
    subject: '',
  },
  signatureMarks: null,
  signatureMarksNextTask: null,
  signatures: {
    uploadSignature: null,
    drawSignatureFull: null,
    drawSignatureTrim: null,
    newSignature: null,
  },
  signaturesNextTask: {
    uploadSignature: null,
    drawSignatureFull: null,
    drawSignatureTrim: null,
    newSignature: null,
  },
  taskResult: null,
  isTriedSubmit: false,
  highlightedMarkKey: null,
  currentMarkKey: null,
  markKeysArray: [],
  pointerSwitch: null,
  lastPhone: null,
  lastPhoneNextTask: null,
  isCamOpened: false,
  selfieImg: null,
  selfieImgNextTask: null,
  selfieGuid: null,
  selfieGuidNextTask: null,
  scale: 100,
  taskCounter: null,
}

export const getEnvelope = createAsyncThunk(
  'shared/getenvelope',
  async (sharedLinkOguid: string, { rejectWithValue }) => {
    try {
      const response = await SharedService.getEnvelope(sharedLinkOguid)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const declineTask = createAsyncThunk(
  'shared/declineTask',
  async (sharedLinkOguid: string, { rejectWithValue }) => {
    try {
      const response = await SharedService.declineTask(sharedLinkOguid)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const completeTask = createAsyncThunk(
  'shared/completeTask',
  async ({sharedLinkOguid, data }: ICompleteTask, { rejectWithValue }) => {
    try {
      const response = await SharedService.completeTask(sharedLinkOguid, data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const complete2Signers = createAsyncThunk(
  'shared/complete2Signers',
  async ({sharedLinkOguid, data }: ICompleteTask, { rejectWithValue }) => {
    try {
      const response = await SharedService.complete2Signers(sharedLinkOguid, data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const getDocumentFile = createAsyncThunk(
  'shared/getDocumentType',
  async ({ sharedLinkOguid, fileName, fileIndex, docType }: IFileDownload, { rejectWithValue }) => {
    try {
      const resp = await SharedService.getDocumentFile(sharedLinkOguid, fileIndex, docType)

      const regExpFilename = /filename="(?<filename>.*)"/
      const filenameSigned = regExpFilename.exec(resp.headers['content-disposition'])?.groups?.filename
      const decodedFilename = filenameSigned ? decodeURI(filenameSigned) : filenameSigned

      fileDownload(resp.data, decodedFilename ?? fileName )
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const phoneConfirm = createAsyncThunk(
  'shared/phoneConfirm',
  async ({ sharedLinkOguid, data }: IPhoneConfirmData, { rejectWithValue }) => {
    try {
      const response = await SharedService.phoneConfirm(sharedLinkOguid, data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const selfieShelf = createAsyncThunk(
  'shared/selfieShelf',
  async ({sharedLinkOguid, data }: ISelfieShelfData, { rejectWithValue }) => {
    try {
      const response = await SharedService.selfieShelf(sharedLinkOguid, data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  },
)

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setSignatureMarks: (state, action: PayloadAction<ISignatureMarksData>) => {
      const { signatureMarks, isNextTask } = action.payload

      const stateSectionKey = isNextTask
        ? 'signatureMarksNextTask'
        : 'signatureMarks'

      state[stateSectionKey] = signatureMarks
    },
    setUploadSignature: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask } = action.payload

      const stateSectionKey = isNextTask
      ? 'signaturesNextTask'
      : 'signatures'

      state[stateSectionKey].uploadSignature = value
    },
    setDrawSignatureFull: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask } = action.payload

      const stateSectionKey = isNextTask
      ? 'signaturesNextTask'
      : 'signatures'

      state[stateSectionKey].drawSignatureFull = value
    },
    setDrawSignatureTrim: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask } = action.payload

      const stateSectionKey = isNextTask
      ? 'signaturesNextTask'
      : 'signatures'

      state[stateSectionKey].drawSignatureTrim = value
    },
    setNewSignature: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask } = action.payload

      const stateSectionKey = isNextTask
      ? 'signaturesNextTask'
      : 'signatures'

      state[stateSectionKey].newSignature = value
    },
    setTaskComplete: (state) => {
      state.taskResult = TaskResult.COMPLETE
    },
    setTaskDecline: (state) => {
      state.taskResult = TaskResult.DECLINE
    },
    setIsTriedSubmit: (state, action: PayloadAction<boolean>) => {
      state.isTriedSubmit = action.payload
    },
    resetSignatures: (state) => {
      state.signatures = {
        uploadSignature: null,
        drawSignatureFull: null,
        drawSignatureTrim: null,
        newSignature: null,
      }
    },
    setCurrentMarkKey: (state, action: PayloadAction<Nullable<string>>) => {
      state.currentMarkKey = action.payload
    },
    setHighlightedMarkKey: (state, action: PayloadAction<Nullable<string>>) => {
      state.highlightedMarkKey = action.payload
    },
    setMarksKeyArray: (state, action: PayloadAction<string[]>) => {
      state.markKeysArray = action.payload
    },
    togglePointer: (state) => {
      state.pointerSwitch = !state.pointerSwitch
    },
    setLastPhone: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask = false } = action.payload

      const stateSectionKey = isNextTask
      ? 'lastPhoneNextTask'
      : 'lastPhone'

      state[stateSectionKey] = value
    },
    setIsCamOpened: (state, action: PayloadAction<boolean>) => {
      state.isCamOpened = action.payload
    },
    setSelfieImg: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask = false } = action.payload

      const stateSectionKey = isNextTask
      ? 'selfieImgNextTask'
      : 'selfieImg'

      state[stateSectionKey] = value
    },
    setSelfieGuid: (state, action: PayloadAction<IValueFromModeData>) => {
      const { value, isNextTask = false } = action.payload

      const stateSectionKey = isNextTask
      ? 'selfieGuidNextTask'
      : 'selfieGuid'

      state[stateSectionKey] = value
    },
    setScale: (state, action: PayloadAction<number>) => {
      state.scale = action.payload
    },
    setTaskCounter: (state, action: PayloadAction<number>) => {
      state.taskCounter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEnvelope.fulfilled, (state, action) => {
      if (!action.payload) return

      state.envelope = action.payload
      state.markKeysArray = getMarkKeysArray(action.payload)
    })
  },
})

const { actions, reducer } = sharedSlice

export const {
  setSignatureMarks,
  setUploadSignature,
  setDrawSignatureFull,
  setDrawSignatureTrim,
  setNewSignature,
  setTaskComplete,
  setTaskDecline,
  setIsTriedSubmit,
  resetSignatures,
  setHighlightedMarkKey,
  setCurrentMarkKey,
  setMarksKeyArray,
  togglePointer,
  setLastPhone,
  setIsCamOpened,
  setSelfieImg,
  setSelfieGuid,
  setScale,
  setTaskCounter,
} = actions

export default reducer
