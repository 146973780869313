export const isDevelopment =
  process.env.REACT_APP_API_ENV === 'development'

export const BASE_URL = isDevelopment
  ? 'https://api-gd-backend-dev.dev.info-logistics.eu/'
  : `https://api.${window.location.hostname.replace(/^[^.]+\./g, '')}/`


export enum LocalStorage {
  LANGUAGE = 'i18nextLng',
  LAST_URL = 'last_url',
  REDIRECT_TO_URL = 'redirect_to_url'
}

export const LocalStorageRootNamespace = 'gd-shared'

export const lookupSessionStorage = 'baseUrl'

export const Languages = {
  EN: 'en',
  PT: 'pt',
  RU: 'ru',
}

export const LanguageShort = {
  en: 'En',
  pt: 'Pt',
  ru: 'Ru',
}

export const LanguageFull = {
  en: 'English',
  pt: 'Português',
  ru: 'Русский',
}

export const NS = [
  'common',
  'notification',
]

export const PATHNAME_SEPARATOR = '/'

export const ScaleBreakpoints = [ 25, 50, 75, 100, 125, 150 ]

export const defaultScale = 100

export const SECONDS_IN_MINUTE = 60

export enum MarkType {
  SIGNATURE = 'SIGNATURE',
  INITIALS = 'INITIALS',
  FULLNAME = 'FULLNAME',
  POSITION = 'POSITION',
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  STRING = 'STRING'
}

export enum State {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum FlowState {
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  SOLVED = 'SOLVED',
  STOPPED = 'STOPPED'
}

export enum EnvelopeActions {
  SIGNATURE_CERTIFICATE = 'signatureCertificate',
  DOWNLOAD_FILE = 'downloadFile',
}

export enum AllowedActions {
  STOP_PROCESS = 'STOP_PROCESS',
  SIGN = 'SIGN',
  DECLINE_SIGN = 'DECLINE_SIGN',
  APPROVE = 'APPROVE',
  DECLINE_APPROVE = 'DECLINE_APPROVE',
}

export enum MenuActions {
  VIEW_LOG = 'viewLog',
  DOWNLOAD_DOC = 'downloadDoc',
  CHANGE_LNG = 'changeLng',
  DECLINE = 'decline'
}

export enum TaskType {
  SIGNING = 'SIGNING',
  APPROVAL = 'APPROVAL',
  ACQUAINTANCE = 'ACQUAINTANCE',
}

export enum Font {
  ARIAL = 'ARIAL',
  /* HELVETICA = 'HELVETICA', */ //Убран до выяснения
  TIMES = 'TIMES',
}

export enum FontType {
  REGULAR = 'REGULAR',
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
}

export enum Modal {
  SIGNATURE_IMAGE_CHOOSE = 'SignatureImageChoose',
  SIGNATURE_IMAGE_DRAW = 'SignatureImageDraw',
  SIGNATURE_IMAGE_UPLOAD = 'SignatureImageUpload',
  PHONE_CONFIRM = 'PhoneConfirm',
  SELFIE_CONFIRM = 'SelfieConfirm',
}

export enum TaskResult {
  COMPLETE = 'SOLVED',
  DECLINE = 'DECLINED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED'
}

export const imageSizeLimitBytes = 204800

export const SignerFullnameLength = {
  MAX: 100,
}

export const SignerPositionLength = {
  MAX: 50,
}

export const SignerInitialsLength = {
  MAX: 100,
}

export const signerFullnameRegexp = /^([\p{L} .@_-])+$/gu

export const signerInitialsRegexp = /^([\p{L} .@_-])+$/gu

export const signerPositionRegexp = /^([\p{L} .,@_-])+$/gu

export const a4DefaultWidth = 595

export enum BreakpointMinWidth {
  DESKTOP = 768,
  MOBILE = 320
}

export enum DocumentType {
  SIGNED = 'SIGNED',
  ORIGINAL = 'ORIGINAL'
}

export enum UrlParams {
  LANG = 'lang',
  REDIRECT_TO = 'redirect_to'
}

export const TIMEOUT = 500

export enum DateFormat {
  FULL_DATE_SLASH = 'MM/dd/yyyy',
  FULL_DATE_DOTS = 'MM.dd.yyyy',
  TIME_ONLY = 'HH:mm:ss',
  HOUR_AND_MINUTES = 'HH:mm',
  DATE_FULL_YEAR_DATEPICKER = 'dd.MM.yyyy',
}

export const SECOND_TASK_POSTFIX = '_2task'

export const invalidCodeMessage = 'INVALID_SMS_CODE'
