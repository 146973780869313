import React, { ReactElement, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setCurrentMarkKey, setHighlightedMarkKey, togglePointer } from '@store/modules/shared'

const PointerButton: FC = (): ReactElement => {
  const { t } = useTranslation('envelope')
  const { currentMarkKey, markKeysArray } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const handleClick = (): void => {
    dispatch(togglePointer())

    if (!currentMarkKey) {
      dispatch(setCurrentMarkKey(markKeysArray[0]))
      dispatch(setHighlightedMarkKey(markKeysArray[0]))
    } else {
      const currentMarkIndex = markKeysArray.findIndex(key => key === currentMarkKey)
      const nextMarkIndex = currentMarkIndex === markKeysArray.length - 1 ? 0 : currentMarkIndex + 1

      dispatch(setCurrentMarkKey(markKeysArray[nextMarkIndex]))
      dispatch(setHighlightedMarkKey(markKeysArray[nextMarkIndex]))
    }
  }

  return (
    <Button
     classes='ml-auto'
     theme='primary'
     onClick={handleClick}
     isDisabled={!markKeysArray.length}
    >
      {currentMarkKey ? t('envelope:pointer.next') : t('envelope:pointer.start')}
    </Button>
  )
}

export default PointerButton
