import React, { ReactElement, FC } from 'react'
import cn from 'classnames'

import { DropdownButton, IconDefaultWidth, ILibDropdownItem, ILibHorizontalPosition } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setScale } from '@store/modules/shared'
import { ScaleBreakpoints } from '@const/consts'

import styles from './ScaleTools.module.scss'
import { getIsMobile } from '@app/utils/utils'

const ScaleTools: FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const { scale } = useAppSelector(state => state.shared)

  const onChangeScale = (item: ILibDropdownItem) => dispatch(setScale(+item.data))

  const setDefaultScale = () => dispatch(setScale(100))

  const getScaleItems = (): Array<ILibDropdownItem> => {
    return ScaleBreakpoints.map((point) => ({ name: point.toString(), translation: point.toString(), data: point }))
  }

  return (
    <div className={cn(styles.scale_wrapper, 'd-flex justify-content-center align-items-center')}>
      <DropdownButton
        buttonTheme='text'
        popoverClasses={cn('mr-4 text-muted')}
        buttonClasses={cn('text-muted', styles.popover)}
        containerClasses={cn(styles.popover)}
        itemClasses={cn('py-1', styles.item)}
        dropdownItems={getScaleItems()}
        onSelect={onChangeScale}
        withoutArrow
        selectedItem={getScaleItems().find(item => item.data === scale)}
        selectedTitle={`${scale}%`}
        horizontalPosition={ILibHorizontalPosition.LEFT}
      />
      <IconDefaultWidth onClick={setDefaultScale} classes='pointer' size={getIsMobile() ? 'md' : 'sm'}/>
    </div>
  )
}

export default ScaleTools
