import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import cn from 'classnames'

import { Tabs } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setSignatureMarks, setNewSignature } from '@store/modules/shared'
import { resetModal } from '@store/modules/modal'
import { MarkType, Modal } from '@const/consts'
import { getIsMobile } from '@utils/utils'
import { getIsNextTask, getRecipient } from '@utils/envelope'
import SignatureModalWrapper from './components/SignatureModalWrapper'
import SignatureDraw from './components/SignatureDraw'
import SignatureUpload from './components/SignatureUpload'
import { GetSignatureTabsContent, ISignatureValue, ISignatureModalProps as IProps, ISignatureFormikProps } from './types'

import styles from './SignatureModal.module.scss'

const SignatureModal: FC<IProps> = ({ currentSignature, setFieldValue }) => {
  const { t } = useTranslation(['envelope', 'common'])
  const { signatureMarks, signatureMarksNextTask, signatures, signaturesNextTask } = useAppSelector(state => state.shared)
  const isNextTask = getIsNextTask()
  const { newSignature, drawSignatureTrim } = isNextTask ? signaturesNextTask : signatures
  const [modalID, setModalId] = useState(Modal.SIGNATURE_IMAGE_DRAW)

  const dispatch = useAppDispatch()

  const hasSignature = getRecipient()?.prepositionedMarks.find(mark => mark.type === MarkType.SIGNATURE)
  const tabs = [
    // Таб с выбором подписи временно скрыт

    /* {
      name: Modal.SIGNATURE_IMAGE_CHOOSE,
      translation: t('envelope:signature.choose'),
    }, */
    {
      name: Modal.SIGNATURE_IMAGE_DRAW,
      translation: t('envelope:signature.draw'),
    },
    {
      name: Modal.SIGNATURE_IMAGE_UPLOAD,
      translation: t('envelope:signature.upload'),
    },
  ]

  useEffect(() => {
    setModalId(!newSignature || newSignature === drawSignatureTrim ? Modal.SIGNATURE_IMAGE_DRAW : Modal.SIGNATURE_IMAGE_UPLOAD)
  }, [])

  const getTabsContent: GetSignatureTabsContent = (formikProps: ISignatureFormikProps) => {
    return {
      // Таб с выбором подписи временно скрыт

      /* [Modal.SIGNATURE_IMAGE_CHOOSE]:
        <div className={tabContentClasses}>
          <SignatureChoose {...formikProps}/>
        </div>, */
      [Modal.SIGNATURE_IMAGE_DRAW]:
        <div className={'full-width'}>
          <SignatureDraw {...formikProps}/>
        </div>,
      [Modal.SIGNATURE_IMAGE_UPLOAD]:
        <div className='d-flex flex-column full-width align-items-start'>
          <div className={cn(styles.signature_content, 'mt-4 full-width')}>
            <SignatureUpload {...formikProps}/>
          </div>
        </div>,
    }
  }

  const handleSubmit = (values: ISignatureValue) => {
    const { newSignature } = values

    dispatch(setNewSignature({ value: newSignature, isNextTask }))
    dispatch(resetModal())

    const signatures = isNextTask ? signatureMarksNextTask : signatureMarks
    if (currentSignature) {
      dispatch(setSignatureMarks({ signatureMarks: {...signatures, [currentSignature]: true}, isNextTask }))
      setFieldValue(`${isNextTask ? 'nextTaskMarks' : 'marks'}.${currentSignature}.value`, newSignature)
    }
  }

  return (
    <Formik
      initialValues={{
        newSignature: newSignature || null || '',
      }}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <form noValidate onSubmit={props.handleSubmit}>
          <SignatureModalWrapper hasSignature={!!hasSignature}>
            {!getIsMobile() ? hasSignature && (
              <Tabs
                content={getTabsContent(props)}
                tabs={tabs}
                theme='flat'
                activeTab={modalID}
                classes={styles.signature_tabs}
              />
            ) : (
              <div className={'full-width'}>
                <SignatureDraw {...props}/>
              </div>
            )}
          </SignatureModalWrapper>
        </form>
      )}
    </Formik>
  )
}

export default SignatureModal
