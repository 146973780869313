import { ReactNode } from 'react'

export interface IModalWrapperProps {
  children: ReactNode
}

export interface IPhoneConfirmModalProps {
  codeTimeout: number
  codeValue: string
  errorText?: string
  setCodeTimeout: (timeout: number) => void
  handleFinishSigning: () => void
  setCodeValue: (code: string) => void
  setTranId: (tranId: string) => void
}

export enum PhoneVerifyInput {
  CODE = 'code',
  PHONE = 'phone'
}
