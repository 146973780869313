import React, { ReactElement, FC, useEffect, useState } from 'react'
import { AxiosResponseHeaders } from 'axios'
import cn from 'classnames'

import { useAppSelector } from '@hooks'
import { Nullable } from '@store/types'
import { ILogoWrapperProps as IProps } from './types'
import SharedService from '@services/shared'

import styles from './Logo.module.scss'

const LogoWrapper: FC<IProps> = ({ classes, customClasses }): ReactElement => {
  const [logoUrl, setLogoUrl] = useState<Nullable<string>>(null)
  const { envelope } = useAppSelector(state => state.shared)
  const defaultLogoPath = '/assets/img/logo.svg'

  useEffect(() => {
    const { boxOguid } = envelope.sender

    if (!boxOguid.length) return

    SharedService.getBoxLogo(boxOguid)
      .then(({ data, headers }) => setLogoUrl(getImageUrl(data, headers)))
      .catch(setDefaultImageUrl)
  },[envelope])

  const setDefaultImageUrl = () => setLogoUrl(defaultLogoPath)

  const getImageUrl = (data: string, headers: AxiosResponseHeaders): string => {
    let mimeType = headers['content-type']

    if (mimeType.includes('xml')) mimeType = 'image/svg+xml'
    const blob = new Blob([data], { type: mimeType })

    return URL.createObjectURL(blob)
  }

  return (
    <img
      src={logoUrl ?? defaultLogoPath}
      alt='greenDocs'
      className={cn(
        styles.logo,
        logoUrl && (logoUrl !== defaultLogoPath) ? cn(styles.logo_custom, customClasses) : classes,
      )}
    />
  )
}

export default LogoWrapper
