import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Ellipsis } from '@infologistics/frontend-libraries'

import { IPagesDividerProps as IProps } from './types'
import styles from './PagesDivider.module.scss'

const PagesDivider: FC<IProps> = ({
  filename,
  pageWidth,
  pageIndex,
  pageWrapperWidth,
  pageTotal,
  paddingLeft = 0,
  isCenterPosition,
}) => {
  const { t } = useTranslation('envelope')

  const pageInfoStyles = pageIndex ? { maxWidth: `${pageWidth}px` } : {}
  const winWidth = window.screen.width
  const isAllScreenPage = pageWrapperWidth < winWidth
  const centerPositionLeft = ((isAllScreenPage ? winWidth : pageWrapperWidth) - (pageWidth ?? 0)) / 2
  const left = isCenterPosition ? `-${centerPositionLeft}px` : isAllScreenPage
    ? `${(winWidth - 16 - pageWrapperWidth) / (paddingLeft ? -1 : -2)}px`
    : `-${paddingLeft + 8}px`
  const width = isAllScreenPage
    ? `100vw`
    : `${pageWrapperWidth + paddingLeft + 8}px`

  const dividerStyles = { left, width }

  return (
    <>
      <div className='text-muted font-xs d-flex justify-content-between' style={pageInfoStyles}>
        <p className={styles.filename_title}><Ellipsis>{filename}</Ellipsis></p>
        {pageIndex && <p>{t('envelope:pageTitle', {pageIndex, pageTotal})}</p>}
      </div>
      <div className={cn(styles.divider)} style={dividerStyles}/>
    </>
  )
}

export default PagesDivider
