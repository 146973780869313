import React, { FC } from 'react'
import cn from 'classnames'

import { useAppSelector, useAppDispatch } from '@hooks'
import { setCurrentMarkKey } from '@store/modules/shared'
import { Nullable } from '@store/types'
import { checkMarkActivity, getCheckboxValue } from '@utils/envelope'
import { ICheckboxMarkProps as IProps } from './types'

import styles from './CheckboxMark.module.scss'

const CheckboxMark: FC<IProps> = ({
  baseStyles,
  isNextTaskMark,
  markKey: key,
  touched,
  values,
  getFontTypeClass,
  renderTooltip,
  setFieldValue,
  handleBlur,
}) => {
  const { isTriedSubmit, highlightedMarkKey } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const { marks, nextTaskMarks } = values
  const currentTaskMarks = isNextTaskMark && nextTaskMarks ? nextTaskMarks : marks
  const isInactive = checkMarkActivity(isNextTaskMark)
  const { fontSize, fontType, isReadOnly, isRequired, tooltip, value } = currentTaskMarks[key]

  const hasError = (isTriedSubmit || Object.keys(touched).includes(key)) && isRequired && !getCheckboxValue(value)

  const checkboxClasses = cn(
    styles.checkbox,
    'd-flex align-items-center',
    getFontTypeClass(fontType),
    isReadOnly ? styles.checkbox_disabled : 'pointer',
    hasError && styles.checkbox_error,
    highlightedMarkKey === key && styles.checkbox_focused,
    isInactive && styles.checkbox_inactive,
  )

  const checkboxStyle = {
    lineHeight: `${fontSize}px`,
  }

  const handleCheckboxChange = (key: string, isReadOnly: boolean): void => {
    if (isReadOnly || isInactive) return

    dispatch(setCurrentMarkKey(key))

    let value: Nullable<string | boolean> = currentTaskMarks[key].value
    if (value && typeof value !== 'boolean') value = !!+value

    setFieldValue(`${isNextTaskMark ? 'nextTaskMarks' : 'marks'}.${key}.value`, !value)
  }

  return (
    <>
      <div
        className={checkboxClasses}
        style={Object.assign(baseStyles, checkboxStyle)}
        id={key}
        onClick={() => handleCheckboxChange(key, isReadOnly)}
        onMouseUp={handleBlur}
      >
        {value && !!+value && 'X'}
      </div>
      {renderTooltip(tooltip, key)}
    </>
  )
}

export default CheckboxMark
