import { AxiosError } from 'axios'
import i18n from 'i18next'
import { FormikErrors, FormikTouched } from 'formik'
import { DateTime } from 'luxon'
import { PDFPageProxy } from 'pdfjs-dist'

import {
  ILibBasicNotificationOptions,
  ILibModalSizes,
  showBasicNotification,
  showErrorNotification,
} from '@infologistics/frontend-libraries'

import { IEnvelope, IPrepositionMarkWithKey } from '@store/modules/shared/types'
import { BASE_URL, BreakpointMinWidth, DateFormat, Languages, lookupSessionStorage, PATHNAME_SEPARATOR, State, UrlParams } from '@const/consts'
import LocalStorageService from '@services/localStorage'

export const catchAxiosError = (err: unknown, rejectWithValue: (value: unknown) => void): void => {
  if (!isAxiosError(err)) return

  return rejectWithValue(err.response?.data)
}

export const isAxiosError = (candidate: any): candidate is AxiosError => candidate.isAxiosError === true

export const displayErrorNotification = (response: AxiosError<any>): void => {
  const { language = Languages.RU, getResource } = i18n
  const errorMessage = String(getResource(language, 'notification', 'errorContent'))

  showErrorNotification({
    content: response?.message || errorMessage,
    title: '',
  })
}

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result?.toString() || '')
    reader.onerror = error => reject(error)
  })
}

export const getBaseUrl = (): string => sessionStorage.getItem(lookupSessionStorage) ?? BASE_URL

export const displayBasicNotification = (options: ILibBasicNotificationOptions): void => {
  const { content, title, type } = options
  const { language, getResource } = i18n

  showBasicNotification({
    content: content || getResource(language, 'notification', 'successContent'),
    title: title || getResource(language, 'notification', 'successTitle'),
    type,
  })
}

export const hasError = (name: string, errors?: FormikErrors<any>, touched?: FormikTouched<any>): boolean =>
  !!(errors?.[name] && touched?.[name])

export const getErrorText = (name: string, errors?: FormikErrors<any>): string =>
  errors?.hasOwnProperty(name) ? String(errors[name]) : ''

export const getPrepMarks = (envelope: IEnvelope): IPrepositionMarkWithKey[] => {
  const flow = envelope.flow.find(flow => flow.state === State.IN_PROGRESS)
  const task = flow?.tasks.find(task => task.oguid === envelope?.boxTaskOguid)

  return (task?.recipient ?? null)?.prepositionedMarks ?? []
}

export const getIsMobile = (): boolean => window.screen.width < BreakpointMinWidth.DESKTOP

export const getModalSize = (): ILibModalSizes => {
  if (window.screen.width > 510 && getIsMobile()) return ILibModalSizes.SMALL
  else if (window.screen.width <= 510) return ILibModalSizes.EXTRA_SMALL
  else return ILibModalSizes.SEMI_MEDIUM_L
}

export const getModalSelfieSize = (): ILibModalSizes => {
  const semiSmallModalWidth = 400

  if (window.screen.width > semiSmallModalWidth && getIsMobile())
    return ILibModalSizes.SEMI_SMALL
  else if (window.screen.width <= semiSmallModalWidth)
    return ILibModalSizes.EXTRA_SMALL
  else return ILibModalSizes.MEDIUM
}

export const getSharedLinkOguid = (): string => window.location.pathname.substring(1).split(PATHNAME_SEPARATOR)[0]

export const getUrlParams = (): string => {
  const paramsString = window.location.search
  const searchParams = new URLSearchParams(paramsString)

  let lang = searchParams.get(UrlParams.LANG)
  if (!lang || lang && !Object.values(Languages).includes(lang)) lang = Languages.EN

  LocalStorageService.setRedirectUrl()

  history.pushState(null, '', window.location.origin + window.location.pathname)

  return lang
}

export const getMarkKeysArray = (envelope: IEnvelope): string[] => {
  const marks = getPrepMarks(envelope)
  if (!marks) return []

  return marks.sort(sortPrepMarks).map(mark => mark.key)
}

export const sortPrepMarks = (x: IPrepositionMarkWithKey, y: IPrepositionMarkWithKey): number => {
  if (x.fileIndex < y.fileIndex) return -1
  if (x.fileIndex > y.fileIndex) return 1

  if (x.fileIndex === y.fileIndex) {
    if (x.pageNumber < y.pageNumber) return -1
    if (x.pageNumber > y.pageNumber) return 1
    if (x.pageNumber === y.pageNumber) {
      return x.topLeftY - y.topLeftY
    }
  }
  return 0
}

export const addItemToArr = (arr: Array<PDFPageProxy | number>, item: Array<PDFPageProxy | number>, condition: boolean): Array<any> =>
  condition ? [...arr, ...item] : [...item, ...arr]

export const getDate = (value: string): string => {
  const { language, getResource } = i18n

  return `
    ${ getLocaleDateFormat(value, DateFormat.FULL_DATE_DOTS, language) }
    ${ getResource(language, 'common', 'at') }
    ${ getDateFormat(value, DateFormat.HOUR_AND_MINUTES) }
  `
}

const getDateFormat = (value: string, format: DateFormat): string =>
  DateTime.fromISO(value).toFormat(format)

const getLocaleDateFormat = (value: string, format: DateFormat, locale: string): string =>
  DateTime.fromISO(value)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_SHORT)
