import React, { CSSProperties, FC, useEffect, useState } from 'react'
import cn from 'classnames'

import { Layout, Notifications } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { getEnvelope, setTaskCounter } from '@store/modules/shared'
import Envelope from '@views/Envelope'
import CompleteTask from '@views/Envelope/components/CompleteTask'
import { displayErrorNotification, getIsMobile, getSharedLinkOguid } from '@utils/utils'
import { getIsDoubleSigning } from '@utils/envelope'
import HeaderWrapper from '../HeaderWrapper'
import Loader from '../Loader'
import Menu from './components/Menu'
import ChangeApiBtn from '../ChangeAPIBtn'

const LayoutWrapper: FC = () => {
  const dispatch = useAppDispatch()
  const { envelope, taskResult } = useAppSelector(state => state.shared)
  const { isShownFinalMessage } = useAppSelector(state => state.app)
  const [isEnvelopeLoading, setIsEnvelopeLoading] = useState(true)

  useEffect(() => {
    const sharedOguid = getSharedLinkOguid()
    dispatch(getEnvelope(sharedOguid))
      .unwrap()
      .then(() => {
        setIsEnvelopeLoading(false)
        if (getIsDoubleSigning()) dispatch(setTaskCounter(0))
      })
      .catch(displayErrorNotification)
  },[])

  const getHeaderStyles = (): CSSProperties => {
    const headerHeights = [50, 82, 114]
    const headerLineCountMobile = getIsDoubleSigning() && !taskResult ? 3 : 2
    const headerLineCount = getIsMobile() ? headerLineCountMobile : 1

    return {
      '--header-height': `${headerHeights[headerLineCount - 1]}px`,
    } as CSSProperties
  }

  return (
    <div style={getHeaderStyles()}>
      <Notifications />
      <Layout externalClass={cn('fl-layout-full')}>
        <HeaderWrapper />
        <Menu/>
        {process.env.REACT_APP_API_ENV === 'development' && <ChangeApiBtn/>}
        { isShownFinalMessage ? <CompleteTask/> : envelope.oguid && !isEnvelopeLoading ?
          <Envelope />
          : (
          <Loader />
        )}
      </Layout>
    </div>
  )
}

export default LayoutWrapper
