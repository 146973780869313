import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import scrollIntoView from 'scroll-into-view'
import cn from 'classnames'

import {
  LibTooltipPosition,
  Tooltip,
} from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { Nullable } from '@store/types'
import { Font, FontType, MarkType, TIMEOUT } from '@const/consts'
import { setMarksKeyArray } from '@store/modules/shared'
import { IPrepositionMarks } from '@store/modules/shared/types'
import { getIsMobile } from '@utils/utils'
import { checkMarkFullness, getIsNextTask } from '@utils/envelope'
import CheckboxMark from './marks/CheckboxMark'
import SelectMark from './marks/SelectMark'
import SignatureMark from './marks/SignatureMark'
import StringMark from './marks/StringMark'
import TextMark from './marks/TextMark'

import { IMarksFormProps, MarkRefsArray } from './types'

import styles from '@views/Envelope/Envelope.module.css'

const defaultFontSize = 12
const defaultInputWidth = 25

const MarksForm: FC<IMarksFormProps> = (props) => {
  const {
    envelopeWrapperRef,
    fileIndex,
    scale,
    pageIndex,
    pageSizes,
    pageScrollTop,
    values,
    setPointerTop,
    setPointerMobileTop,
  } = props
  const { t } = useTranslation('envelope')
  const { marks, nextTaskMarks } = values

  const formRef = useRef<HTMLDivElement>(null)
  const marksRefArr = useRef<MarkRefsArray[]>([])
  const { currentMarkKey, pointerSwitch, highlightedMarkKey, taskCounter } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()
  const [isSelectOpened, setIsSelectOpened] = useState(false)

  useEffect(() => {
    const ref = marksRefArr.current.find(mark => mark.key === currentMarkKey)?.ref
    if (ref && currentMarkKey) {
      scrollIntoView(ref, { time: TIMEOUT })
      if (!getIsMobile()) {
        setTimeout(() => {
          setPointerTop(ref.getClientRects()[0].top)
        }, TIMEOUT)
      }
    }
  }, [pointerSwitch])

  useEffect(() => {
    const ref = marksRefArr.current.find(mark => mark.key === currentMarkKey)?.ref

    if (ref && currentMarkKey && getIsMobile()) {
      setTimeout(() => {
        let markOffset = ref.clientHeight * scale / 2

        if (getMarks(getIsNextTask())[currentMarkKey].type === MarkType.SIGNATURE) markOffset = 0
        setPointerMobileTop(pageScrollTop + ref.offsetTop + markOffset)
      }, 250) /* half of scroll time */
    }
  }, [currentMarkKey])

  useEffect(() => {
    const curentTaskMarks = getMarks(getIsNextTask())
    const newMarksKeys = Object.keys(curentTaskMarks).filter(key => !checkMarkFullness(curentTaskMarks[key]))

    dispatch(setMarksKeyArray(newMarksKeys))
  }, [values, taskCounter])

  const getMarks = (isNextTask: boolean): IPrepositionMarks =>
    isNextTask && nextTaskMarks ? nextTaskMarks : marks

  const renderMarks = (key: string, fileIndex: number, pageIndex: number, isNextTask = false): Nullable<ReactElement> => {
    const { fileIndex: markFileIndex, pageNumber, topLeftX, topLeftY, type } = getMarks(isNextTask)[key]

    const needOffsetTypes = [MarkType.CHECKBOX, MarkType.FULLNAME, MarkType.INITIALS, MarkType.POSITION, MarkType.STRING]

    const markStyles = {
      top: `${topLeftY * pageSizes.height * scale / 100}px`,
      left: `${topLeftX * pageSizes.width * scale / 100}px`,
      transform: `scale(${scale}) translate(${needOffsetTypes.includes(type) ? '-2px, -2px' : '0'})`,
    }
    if (type === MarkType.SIGNATURE) markStyles['zIndex'] = 1
    else if (type === MarkType.SELECT && currentMarkKey === key) markStyles['zIndex'] = 2

    return (
      markFileIndex === fileIndex && pageNumber === pageIndex
        ? <div className={styles.mark} key={key} style={ markStyles } ref={(item) => addToRefs(item, key)}>
            {renderMark(key, isNextTask)}
          </div>
        : null
    )
  }

  const renderMark = (key: string, isNextTask: boolean): ReactElement | null => {
    const {
      type,
      color,
      fontType,
      fontSize,
      font,
      height,
      width,
    } = getMarks(isNextTask)[key]

    const classes = cn(getFontTypeClass(fontType), 'm-0')
    const style = {
      fontSize: fontSize ?? defaultFontSize,
      fontFamily: getFont(font),
      color: color ?? 'var(--dark)',
    }

    switch (type) {
      case MarkType.FULLNAME:
        return renderTextMark(key, classes, style, isNextTask)

      case MarkType.INITIALS:
        return renderTextMark(key, classes, style, isNextTask)

      case MarkType.POSITION:
        return renderTextMark(key, classes, style, isNextTask)

      case MarkType.STRING:
        return renderTextMark(key, classes, style, isNextTask)

      case MarkType.TEXT: {
        return (
          <TextMark
            baseStyles={style}
            classes={classes}
            height={(height ?? 0) * pageSizes.height / 100}
            markKey={key}
            width={(width ?? 0) * pageSizes.width / 100}
            renderTooltip={renderTooltip}
            isNextTaskMark={isNextTask}
            {...props}
          />
        )
      }

      case MarkType.SELECT: {
        return (
          <SelectMark
            baseStyles={style}
            classes={classes}
            markKey={key}
            renderTooltip={renderTooltip}
            width={(width ?? defaultInputWidth) / 100 * pageSizes.width}
            setIsSelectOpened={setIsSelectOpened}
            isNextTaskMark={isNextTask}
            {...props}
          />
        )
      }

      case MarkType.CHECKBOX: {
        return (
          <CheckboxMark
            baseStyles={style}
            markKey={key}
            getFontTypeClass={getFontTypeClass}
            renderTooltip={renderTooltip}
            isNextTaskMark={isNextTask}
            {...props}
          />
        )
      }

      case MarkType.SIGNATURE:
        return renderSignature(key, isNextTask)

      default:
        return null
    }
  }

  const renderTooltip = (tooltip: Nullable<string>, key: string): Nullable<ReactElement> => (
    !isSelectOpened ?
      key !== highlightedMarkKey ? (
        !tooltip ? null : <Tooltip
          classes='font-xs'
          position={LibTooltipPosition.TOP}
          boundingParent={envelopeWrapperRef}
        >
          {tooltip}
        </Tooltip>
        )
      : (
        <div className={cn('fl-tooltip', 'absolute', 'bg-light', 'fl-tooltip_open', 'top')}>
          <div className='p-2 font-xs'>{tooltip ?? t('envelope:fillMark')}</div>
        </div>
      )
    : null
  )

  const renderTextMark = (key: string, classes: string, style: object, isNextTask: boolean): ReactElement => {
    const inputWidth = ((getMarks(isNextTask)[key].width ?? defaultInputWidth) / 100) * pageSizes.width

    return (
      <StringMark
        baseStyles={style}
        classes={classes}
        markKey={key}
        renderTooltip={renderTooltip}
        width={inputWidth}
        isNextTaskMark={isNextTask}
        {...props}
      />
    )
  }

  const renderSignature = (key: string, isNextTask: boolean): ReactElement => {
    const { tooltip, scale: signatureScale } = getMarks(isNextTask)[key]

    return (
      <SignatureMark
        signatureKey={key}
        tooltip={tooltip}
        signatureScale={signatureScale}
        renderTooltip={renderTooltip}
        isNextTaskMark={isNextTask}
        {...props}
      />
    )
  }

  const getFont = (font: Nullable<string>): string => {
    switch (font) {
      case Font.ARIAL:
        return 'Arial'
      case Font.TIMES:
        return 'Times New Roman'
      default:
        return ''
    }
  }

  const getFontTypeClass = (fontType: Nullable<FontType>): string => {
    switch (fontType) {
      case FontType.BOLD:
        return 'fw-600'
      case FontType.ITALIC:
        return styles.italic
      case FontType.REGULAR:
        return ''
      default:
        return ''
    }
  }

  const addToRefs = (item: HTMLDivElement | null, key: string) => {
      if (item && !marksRefArr.current.find(mark => mark.key === key)) {
      marksRefArr.current.push({
        key,
        ref: item,
      })
    }
  }

  return (
    <div className={styles.marks_wrapper} ref={formRef}>
      {Object.keys(marks).map((key) => renderMarks(key, fileIndex, pageIndex))}
      {nextTaskMarks && Object.keys(nextTaskMarks).map((key) => renderMarks(key, fileIndex, pageIndex, true))}
    </div>
  )
}

export default MarksForm
