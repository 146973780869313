import { ILocalStorage } from './types'
import { LocalStorageRootNamespace } from '@const/consts'
import { Nullable } from '@store/types'

class LocalStorageHelper {
  private readonly rootNamespace: string

  public constructor(
    rootNamespace: string,
  ) {
    this.rootNamespace = rootNamespace
  }

  public getItem<T extends ILocalStorage, K extends keyof T, V extends T[K]>(property: K & string): Nullable<V> {
    const value = localStorage.getItem(this.getItemKey(property))
    return value ? JSON.parse(value) as V : null
  }

  public setItem<T extends ILocalStorage, K extends keyof T, V extends T[K]>(
    property: K,
    value: V,
  ): void {
    const serialized = JSON.stringify(value)
    localStorage.setItem(this.getItemKey( property as string), serialized)
  }

  public removeItem<T extends ILocalStorage, K extends keyof T>(property: K & string): void {
    localStorage.removeItem(this.getItemKey(property))
  }

  private getItemKey(property: string): string {
    return [
      this.rootNamespace,
      property,
    ].join('.')
  }
}

export default new LocalStorageHelper(LocalStorageRootNamespace)