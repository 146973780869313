import React, { ReactElement } from 'react'
import {
  components,
  DropdownIndicatorProps,
  MenuListProps,
  ControlProps,
  InputProps,
  ValueContainerProps,
  GroupBase,
} from 'react-select'

import { IconAngle } from '@infologistics/frontend-libraries'

import styles from '../SelectWrapper/SelectWrapper.module.scss'

const { Control, DropdownIndicator, Input, MenuList, ValueContainer } = components

export function customValueContainer<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
> (props: ValueContainerProps<Option, false, Group>): ReactElement {
  const { children, ...rest } = props
  return (
    <ValueContainer className={'p-0'} {...rest}>
      {children}
    </ValueContainer>
  )
}

export function customInput<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
> (props: InputProps<Option, false, Group>, key: string): ReactElement {
  const { children, ...rest } = props
  return (
    <Input name={key} className={styles.input} {...rest}>
      {children}
    </Input>
  )
}

export function customControl<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
> (props: ControlProps<Option, false, Group>): ReactElement {
  const { children, ...rest } = props
  return (
    <Control className={styles.select__control} {...rest}>
      {children}
    </Control>
  )
}

export function customMenuList<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
> (props: MenuListProps<Option, false, Group>): ReactElement {
  const { children, ...rest } = props
  return (
    <MenuList className='scrollbar-content' {...rest}>
      {children}
    </MenuList>
  )
}

export function customDropdownIndicator<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
> (props: DropdownIndicatorProps<Option, false, Group>): ReactElement {
  return (
    <DropdownIndicator {...props} className={styles.select__indicator}>
      <IconAngle size='xs' color='primary-500' rotation={props.selectProps.menuIsOpen ? '180' : '0'} />
    </DropdownIndicator>
  )
}
