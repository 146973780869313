import React, { ReactElement } from 'react'
import Select, {
  CSSObjectWithLabel,
  GroupBase,
  Props as ReactSelectProps,
  SelectComponentsConfig,
} from 'react-select'
import cn from 'classnames'

import { getValue } from './utils'
import { ISelectProps } from './types'

import styles from './SelectWrapper.module.scss'
import { customControl, customDropdownIndicator, customInput, customMenuList, customValueContainer } from '../CustomComponents'

export const SelectWrapper = <
  Option,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: ReactSelectProps<Option, false, Group> & ISelectProps<Option>,
): ReactElement => {
  const {
    classes,
    components,
    commonTextStyles,
    controlMinHeight,
    containerWidth,
    isFocused,
    hasError,
    isDisabled,
    markKey: key,
    menuMinWidth = 'auto',
    menuWidth = '100%',
    options = [],
    rawValue = null,
    isInactive = false,
    ...restProps
  } = props

  const errorClass = hasError && styles.select_error
  const selectClasses = cn(
    styles.select,
    classes,
    errorClass,
    isDisabled && styles.select_disabled,
    isFocused && styles.select_focused,
    isInactive && styles.select_inactive,
  )

  const selectStyles = {
    menu: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      minWidth: menuMinWidth,
      width: menuWidth,
    }),

    singleValue: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      ...commonTextStyles,
    }),

    option: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      ...commonTextStyles,
    }),

    control: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      minHeight: controlMinHeight,
    }),

    container: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      width: containerWidth,
    }),

    input: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      ...commonTextStyles,
    }),
  }

  const allComponents: SelectComponentsConfig<Option, false, Group> = {
    DropdownIndicator: customDropdownIndicator,
    IndicatorSeparator: null,
    MenuList: customMenuList,
    Control: customControl,
    Input: (props) => customInput(props, key),
    ValueContainer: customValueContainer,
    ...components,
  }

  return (
    <Select
      {...restProps}
      options={options}
      className={selectClasses}
      components={allComponents}
      placeholder={null}
      isDisabled={isDisabled}
      classNamePrefix='select'
      styles={selectStyles}
      value={getValue(options, rawValue)}
    />
  )
}
