import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ILibModalSizes, Modal } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { resetModal } from '@store/modules/modal'
import { IModalProps as IProps } from '@common/ModalWrapper/types'

const ModalWrapper: FC<IProps> = (props) => {
  const {
    children,
    id,
    size = ILibModalSizes.SMALL,
    onCloseModal,
  } = props

  const { t } = useTranslation('common')
  const { modal } = useAppSelector(state => state.modal)
  const dispatch = useAppDispatch()

  return (
    <Modal
      id={id}
      idFromState={modal || ''}
      size={size}
      closeText={t('common:close')}
      hideModal={() => dispatch(resetModal())}
      onCloseModal={onCloseModal}
    >
      {children}
    </Modal>
  )
}

export default ModalWrapper
