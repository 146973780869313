import React, { ReactElement, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { DropdownButton, ILibHorizontalPosition, ILibDropdownItem, Popover, IconDownload } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { EnvelopeActions, FlowState } from '@const/consts'
import { getIsMobile, getSharedLinkOguid } from '@utils/utils'
import { getPointerDisplay } from '@utils/envelope'
import { getDocumentFile } from '@store/modules/shared'
import { setIsOpenedMenu } from '@store/modules/startup'
import UrlService from '@services/urls'

import styles from '../../HeaderWrapper.module.css'

const filenameMaxLen = 40

const DownloadButton: FC = (): ReactElement => {
  const { t } = useTranslation('common')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { envelope, taskResult } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const mobileClasses = cn(
    styles.download_buttons,
    getPointerDisplay(!!taskResult) ? 'ml-2' : 'ml-auto',
    'mr-2',
  )

  const togglePopover = (): void => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const closePopover = (): void => {
    setIsPopoverOpen(false)
  }

  const getDownloadFileItems = (): ILibDropdownItem[] => {
    const { files, flowState } = envelope
    const downloadFileItems: ILibDropdownItem[] = []

    files.forEach(file => {
      const { index, filename } = file

      const item = {
        name: EnvelopeActions.DOWNLOAD_FILE,
        data: index.toString(),
        translation: filename.length > filenameMaxLen ? filename.slice(0, filenameMaxLen) + '...' : filename,
      }

      downloadFileItems.push(item)
    })

    if (flowState === FlowState.SOLVED) {
      const item = {
        name: EnvelopeActions.SIGNATURE_CERTIFICATE,
        translation: t('envelope:signatureCertificate'),
      }

      downloadFileItems.push(item)
    }

    return downloadFileItems
  }

  const handleDownloadFile = (item: ILibDropdownItem): void => {
    switch (item.name) {
      case EnvelopeActions.DOWNLOAD_FILE: {
        const sharedLinkOguid = getSharedLinkOguid()

        const file = envelope.files.find(file => file.index === +item.data)
        const docType = file?.signedProperties ? 'SIGNED' : 'ORIGINAL'

        dispatch(getDocumentFile({ sharedLinkOguid, fileIndex: item.data, fileName: file?.filename ?? '', docType }))

        break
      }
      case EnvelopeActions.SIGNATURE_CERTIFICATE: {
        const protocolUrl = UrlService.getProtocolUrl()
        window.open(protocolUrl)
        break
      }
    }
  }

  const renderPopoverItems = (item: ILibDropdownItem): ReactElement => {
    return (
      <div
        key={item.name + item.data}
        className={cn(styles.download_button_item, 'py-2 px-4')}
        onClick={() => handlePopoverItemClick(item)}
        >
          {item.translation}
      </div>
    )
  }

  const handlePopoverItemClick = (item: ILibDropdownItem): void => {
    handleDownloadFile(item)
    togglePopover()
    dispatch(setIsOpenedMenu(false))
  }

  const renderMobileMenuItem = (): ReactElement => {
    const fileItems = getDownloadFileItems()
    const isMultiDoc = fileItems.length > 1
    const hasSertificate = !!fileItems.find(item => item.name === EnvelopeActions.SIGNATURE_CERTIFICATE)

    return (
      isMultiDoc ? (
        <div className={mobileClasses}>
          <Popover
            horizontalPosition={ILibHorizontalPosition.LEFT}
            buttonComponent={renderMenuItemBtn(true, hasSertificate)}
            withoutArrow
            parentManagement={{
              isOpenFromParent: isPopoverOpen,
              callbackForClose: closePopover,
            }}
          >
            {fileItems.map(renderPopoverItems)}
          </Popover>
        </div>
      ) : renderMenuItemBtn(false, hasSertificate)

    )
  }

  const renderMenuItemBtn = (isMultiDoc: boolean, hasSertificate = false): ReactElement => {
    const firstDoc = getDownloadFileItems()[0]
    const handleBtnClick = () => isMultiDoc ? togglePopover() : handlePopoverItemClick(firstDoc)
    const btnTitleClasses = cn(
      'd-flex align-items-center m-0',
      isMultiDoc && !hasSertificate ? 'ml-2' : 'ml-4',
    )

    return (
      <div
        className={styles.download}
        onClick={handleBtnClick}
      >
        <p className={btnTitleClasses}>
          {t('envelope:downloadDoc')}
          <IconDownload color='gray-500' classes='ml-2'/>
        </p>
      </div>
    )
  }

  return (getIsMobile() ? renderMobileMenuItem() : (
    <div className={cn(styles.download_buttons, 'ml-auto mr-n4')}>
      <DropdownButton
        popoverClasses='mr-6'
        itemClasses={cn(styles.actions_buttons_item, 'p-2')}
        dropdownItems={getDownloadFileItems()}
        onSelect={handleDownloadFile}
        selectedTitle={t('envelope:download')}
        withoutArrow
        horizontalPosition={ILibHorizontalPosition.RIGHT}
      />
    </div>
  ))
}

export default DownloadButton
