import React, { FC, ReactElement, useEffect, useRef, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, Formik, FormikProps } from 'formik'
import { getDocument, GlobalWorkerOptions, version, PDFPageProxy } from 'pdfjs-dist'
import cn from 'classnames'

import {
  Spinner,
  Nullable,
  IconAngleRight,
  ILibModalSizes,
  useStateWithCallback,
  showErrorNotification,
} from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import {
  complete2Signers,
  completeTask,
  setCurrentMarkKey,
  setTaskCounter,
  setHighlightedMarkKey,
  setIsTriedSubmit,
  setScale,
  setSignatureMarks,
  setTaskComplete,
  togglePointer,
} from '@store/modules/shared'
import { IDeclaredSigner, IPrepositionMarks, ISignatures } from '@store/modules/shared/types'
import { setIsShownFinalMessage } from '@store/modules/startup'
import LocalStorageService from '@services/localStorage'
import {
  a4DefaultWidth,
  DocumentType,
  invalidCodeMessage,
  MarkType,
  Modal,
  SECOND_TASK_POSTFIX,
} from '@const/consts'

import {
  displayErrorNotification,
  getIsMobile,
  getModalSize,
  getSharedLinkOguid,
  getModalSelfieSize,
  addItemToArr,
} from '@utils/utils'

import {
  checkSubmitAvailable,
  getMarkByType,
  getRecipient,
  getPointerDisplay,
  getNextTaskRecipient,
  getIsNextTask,
  getIsDoubleSigning,
} from '@utils/envelope'

import { getFilename } from '@utils/envelope'

import { resetModal, setModal } from '@store/modules/modal'
import UrlService from '@services/urls'
import ModalWrapper from '@common/ModalWrapper'
import MarksForm from './components/MarksForm'
import { IMarkFormData } from './components/MarksForm/types'
import PagesDivider from './components/PagesDivider'
import SignatureModal from '../SignatureModal'
import PhoneConfirmModal from '../PhoneConfirmModal'
import SelfieConfirmModal from '../SelfieConfirmModal'
import Footer from '../Footer'
import InfoSidebar from '../InfoSidebar'
import { IPDFInfo, IFileViewerProps as IProps, ICompleteTaskData, IMark, IOneSignerCompleteTaskData } from './types'

import styles from '../../Envelope.module.css'
import pointerMobile from './img/pointerMobile.svg'

const FileViewer: FC<IProps> = ({ envelopeWrapperRef }) => {
  const { t } = useTranslation(['common', 'envelope'])

  const canvasWrapperRefArr = useRef<HTMLDivElement[]>([])
  const contentRef = useRef<HTMLDivElement>(null)
  const pointerRef = useRef<HTMLDivElement>(null)

  const [pageHeights, setPageHeights] = useState<number[]>([])
  const [pageWidths, setPageWidths] = useStateWithCallback<number[]>([])
  const [currentSignature, setCurrentSignature] = useState<Nullable<string>>(null)
  const [loadedFiles, setLoadedFiles] = useState<number[]>([])
  const [isHorScroll, setIsHorScroll] = useState(false)
  const [pagesInFile, setPagesInFile] = useState<number[]>([])
  const [pages, setPages] = useState<PDFPageProxy[]>([])
  const [isCanvasFilled, setIsCanvasFilled] = useState(false)
  const [pointerTop, setPointerTop] = useState(81)
  const [pointerMobileTop, setPointerMobileTop] = useState(0)
  const [geolocation, setGeolocation] = useState<Nullable<string>>(null)
  const [tranId, setTranId] = useState<Nullable<string>>(null)
  const [tranIdNextTask, setTranIdNextTask] = useState<Nullable<string>>(null)
  const [codeValue, setCodeValue] = useState('')
  const [codeValueNextTask, setCodeValueNextTask] = useState('')
  const [codeTimeout, setCodeTimeout] = useState(0)
  const [codeTimeoutNextTask, setCodeTimeoutNextTask] = useState(0)
  const [codeErrorText, setCodeErrorText] = useState(undefined)

  const {
    taskCounter,
    envelope,
    signatures,
    taskResult,
    currentMarkKey,
    markKeysArray,
    signaturesNextTask,
    pointerSwitch,
    selfieGuid,
    selfieGuidNextTask,
    scale,
  } = useAppSelector(state => state.shared)
  const { modal } = useAppSelector(state => state.modal)
  const dispatch = useAppDispatch()

  const canvasWrapperClasses = cn(
    'd-flex px-2',
    isHorScroll ? 'align-items-start' : 'align-items-center',
    isHorScroll && styles.hor_scroll,
    styles.canvas_wrapper,
  )

  if (!GlobalWorkerOptions.workerSrc) {
    GlobalWorkerOptions.workerSrc =
      `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`
  }

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (!codeTimeout) return

      setCodeTimeout((prevTimeout) => prevTimeout - 1)
    }, 1000)
    return () => window.clearInterval(interval)
  }, [codeTimeout])

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (!codeTimeoutNextTask) return

      setCodeTimeoutNextTask((prevTimeout) => prevTimeout - 1)
    }, 1000)
    return () => window.clearInterval(interval)
  }, [codeTimeoutNextTask])

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (e.target?.['innerText'] !== t('envelope:pointer.next')) dispatch(setHighlightedMarkKey(null))
    }
    window.addEventListener('click', handler, false)

    return () => {
      window.removeEventListener('click', handler)
    }
  }, [])

  useEffect(() => {
    const pagesCount = pagesInFile.reduce((acc, num) => acc + num, 0)

    if (loadedFiles.length === envelope.files.length && pages.length === pagesCount) {
      for (let i = 0; i < pages.length; i++) {
        renderCanvas(pages[i], i)
      }
      setIsCanvasFilled(true)
    }
  }, [pages])

  useEffect(() => {
    getDocuments()
    const geo = navigator.geolocation
    geo.getCurrentPosition(getGeolocation)
  }, [envelope])

  useEffect(() => {
    setIsHorScroll(getIsHorizontalScroll())
    setPointerMobileTop(0)
  }, [scale])

  useEffect(() => {
    const isNewHorizontalScroll = getIsHorizontalScroll()

    if (pointerSwitch && isNewHorizontalScroll !== isHorScroll) {
      setIsHorScroll(isNewHorizontalScroll)
    }
  }, [pointerSwitch])

  useEffect(() => {
    canvasWrapperRefArr.current.forEach((ref, index) => {
      if (ref.firstChild) ref.firstChild['style'].height = `${pageHeights[index] * scale / 100}px`
      if (ref.firstChild) ref.firstChild['style'].width = `${pageWidths[index] * scale / 100}px`
    })
  }, [scale])

  useEffect(() => {
    if (loadedFiles.length) {
      const pageWrapperWidth = canvasWrapperRefArr.current[0]?.parentElement?.offsetWidth
      if (pageWrapperWidth && pageWrapperWidth > window.screen.width) {
        const pointerOffset = pointerRef.current?.clientWidth ?? 0
        dispatch(setScale(Math.floor((window.screen.width - 12 - pointerOffset) / pageWrapperWidth * 100)))
      }
    }
  }, [isCanvasFilled])

  const getDocuments = async (): Promise<void> => {
    if (!envelope.oguid) return

    let lastUploadedFile: Nullable<number> = null

    for (let i = 0; i < envelope.files.length; i++) {
      const file = envelope.files.find(file => file.index === i)
      const docType = file?.signedProperties ? DocumentType.SIGNED : DocumentType.ORIGINAL

      const initialURL = UrlService.getPdfUrl(file?.index ?? 0, docType)

      await getDocument(initialURL)
        .promise
        .then(async (pdf: IPDFInfo) => {
          const pagesCount = pdf._pdfInfo.numPages

          const docHeights: number[] = []
          const docWidths: number[] = []
          const docPages: PDFPageProxy[] = []

          for (let j = 1; j <= pagesCount; j++) {
            await pdf.getPage(j).then((page) => {
              docPages.push(page)
              docHeights.push(page.view[3])
              docWidths.push(page.view[2])
            })
          }

          const newPagesInFile = pagesInFile
          newPagesInFile[i] = pagesCount
          setPagesInFile(newPagesInFile)

          const isCorrectLoadOrder = lastUploadedFile === null || i > lastUploadedFile

          setPages(pages => addItemToArr(pages, docPages, isCorrectLoadOrder))
          setPageHeights(heights => addItemToArr(heights, docHeights, isCorrectLoadOrder))
          setPageWidths(widths => addItemToArr(widths, docWidths, isCorrectLoadOrder), () => lastUploadedFile = i)
        })
        .then(() => setLoadedFiles(loadedFiles => [...loadedFiles, file?.index ?? 0]))
        .catch(displayErrorNotification)
    }

    fillSignatureMarks()
    fillSignatureMarks(true)
  }

  const fillSignatureMarks = (isNextTask = false) => {
    const signatureMarks = {}

    const recipient = isNextTask ? getNextTaskRecipient() : getRecipient()
    recipient?.prepositionedMarks.forEach(mark => {
      if (mark.type === MarkType.SIGNATURE) signatureMarks[mark.key] = false
    })

    dispatch(setSignatureMarks({ signatureMarks, isNextTask }))
  }

  const renderCanvas = (page: PDFPageProxy, index: number): void => {
    const viewport = page.getViewport({scale: 2})
    const canvas = document.createElement('canvas')

    canvas && (canvas.height = viewport.height)
    canvas && (canvas.width = viewport.width)
    canvas.style.width = '100%'
    canvas.style.height = '100%'

    const renderContext = {
      canvasContext: canvas.getContext('2d') || '',
      viewport: viewport,
    }

    page.render(renderContext)

    const canvasWrapper = document.createElement('div')
    canvasWrapper.style.height = `${viewport.height * scale / 200}px` /* 100 (pageScale) * 2 (viewportScale) */
    canvasWrapper.style.width = `${viewport.width * scale / 200}px`
    canvasWrapper.append(canvas)
    canvasWrapperRefArr.current[index]?.prepend(canvasWrapper)
  }

  const renderDocuments = (formikProps?: FormikProps<IMarkFormData>): ReactElement => {
    const pagesArr: ReactElement[] = []
    const pointerOffset = (pointerRef.current?.clientWidth ?? 0) - 8
    const paddingLeft = getIsHorizontalScroll(true) ? pointerOffset : 0

    const isActions = envelope.allowedActions.length
    const existPrepMarks = !!getRecipient()?.prepositionedMarks.length
    const isCenterPosition = taskResult || !isActions || isActions && !existPrepMarks
    const pagePositionClass = isCenterPosition ? 'align-items-center' : 'align-items-start'

    for (let i = 0; i < envelope.files.length; i++) {
      const file = envelope.files.find(file => file.index === i)
      if (file) {
        pagesArr.push(renderDocument(file.index, paddingLeft, !!isCenterPosition, formikProps))
      }
    }

    if (!formikProps) {
      return (
        <>
          <div className={cn(styles.wrapper_page, 'd-flex flex-column', pagePositionClass)}>
            {pagesArr}
          </div>
          <Footer/>
        </>
      )
    }

    return (
      <form onSubmit={formikProps.handleSubmit}>
        <FieldArray
          name='marks'
          render={() => (
            <div className={cn(styles.wrapper_page, 'd-flex flex-column', pagePositionClass)} style={{paddingLeft}}>
              {pagesArr}
              {renderPointer()}
            </div>
          )}
        />
        <Footer/>
        <ModalWrapper id={Modal.SIGNATURE_IMAGE_DRAW} size={getModalSize()}>
          <SignatureModal currentSignature={currentSignature} {...formikProps}/>
        </ModalWrapper>
        <ModalWrapper
          id={Modal.PHONE_CONFIRM}
          size={getIsMobile() ? ILibModalSizes.EXTRA_SMALL : ILibModalSizes.SMALL}
          onCloseModal={handleClosePhoneConfirmModal}
        >
          <PhoneConfirmModal
            errorText={codeErrorText}
            codeTimeout={!taskCounter ? codeTimeout : codeTimeoutNextTask}
            codeValue={getIsNextTask() ? codeValueNextTask : codeValue}
            setCodeTimeout={!taskCounter ? setCodeTimeout : setCodeTimeoutNextTask}
            setCodeValue={getIsNextTask() ? setCodeValueNextTask : setCodeValue}
            setTranId={getIsNextTask() ? setTranIdNextTask : setTranId}
            handleFinishSigning={() => handleFormSubmit(formikProps.values)}
          />
        </ModalWrapper>
        <ModalWrapper id={Modal.SELFIE_CONFIRM} size={getModalSelfieSize()}>
          <SelfieConfirmModal handleFinishSigning={()=>handleFormSubmit(formikProps.values)}/>
        </ModalWrapper>
      </form>
    )
  }

  const handleClosePhoneConfirmModal = (): void => {
    setCodeValue('')
    setCodeErrorText(undefined)
  }

  const handleClick = (): void => {
    const prepositionedMarks = getRecipient()?.prepositionedMarks
    if (!prepositionedMarks || !markKeysArray.length) return
    dispatch(togglePointer())

    if (!currentMarkKey) {
      dispatch(setCurrentMarkKey(markKeysArray[0]))
      dispatch(setHighlightedMarkKey(markKeysArray[0]))
    } else {
      const currentMarkIndex = markKeysArray.findIndex(key => key === currentMarkKey)
      const nextMarkIndex = currentMarkIndex === markKeysArray.length - 1 ? 0 : currentMarkIndex + 1

      dispatch(setCurrentMarkKey(markKeysArray[nextMarkIndex]))
      dispatch(setHighlightedMarkKey(markKeysArray[nextMarkIndex]))
    }
  }

  const renderPointer = (): ReactElement => {
    const isPointer = getPointerDisplay(!!taskResult) && isCanvasFilled

    const pointerMobileStyles = {
      top: pointerMobileTop,
      transform: pointerMobileTop ? 'translateY(-50%)' : '',
    }

    const desktopPointerClasses = cn(
      'p-2 font-default d-flex',
      styles.pointer,
      !markKeysArray.length && styles.pointer_disabled,
      getIsHorizontalScroll(true) && styles.pointer_left,
    )

    return (
      <>
        {isPointer && isCanvasFilled && (getIsMobile() ? (
            <div className={styles.pointer_mobile} style={pointerMobileStyles}>
              <img src={pointerMobile}/>
            </div>
          ) :
          (
            <div ref={pointerRef} className={desktopPointerClasses} onClick={handleClick} style={{top: pointerTop + 'px'}}>
              {currentMarkKey ? t('envelope:pointer.next') : t('envelope:pointer.start')}
              <IconAngleRight color='light' classes='ml-3'/>
            </div>
          ))}
      </>
    )
  }

  const renderDocument = (fileIndex: number, paddingLeft: number, isCenterPosition: boolean, formikProps?: FormikProps<IMarkFormData>): ReactElement => {
    const pagesArr = []
    const pagesTotal = pagesInFile[fileIndex]
    const skeletoneWidth = window.screen.width < a4DefaultWidth ? window.screen.width - 20 : a4DefaultWidth
    const filename = getFilename(fileIndex)

    for (let i = 0; i <= pagesTotal - 1; i++) {
      pagesArr.push(renderPage(fileIndex, i+1, paddingLeft, isCenterPosition, formikProps))
    }

    return (
      <Fragment key={`${fileIndex}_${filename}`}>
        {loadedFiles.includes(fileIndex) && pagesArr}
        {!isCanvasFilled && <div className={cn('relative', styles.page_inner)} key={`${filename}`}>
            <div className={cn(styles.page_skeletone, 'relative')} style={{width: `${skeletoneWidth}px`}}>
              <div className={cn(styles.placeholder_spinner, 'absolute')}>
                <Spinner />
                <p className={cn(styles.placeholder_text, 'text-muted font-md')}>{t('common:loading')}</p>
              </div>
            </div>
          {renderDivider(fileIndex, isCenterPosition)}
          </div>
        }
      </Fragment>
    )
  }

  const renderPage = (
    fileIndex: number,
    pageIndex: number,
    paddingLeft: number,
    isCenterPosition: boolean,
    formikProps?: FormikProps<IMarkFormData>,
  ): ReactElement => {

    return (
      <div ref={addToRefs} className={cn('relative', styles.page_inner)} key={`${fileIndex}_${pageIndex}`} style={{display: isCanvasFilled ? 'block' : 'none'}}>
        {loadedFiles.includes(fileIndex) && formikProps && renderMarksForm(formikProps, pageIndex, fileIndex)}
        {renderDivider(fileIndex, isCenterPosition, paddingLeft, pageIndex)}
      </div>
    )
  }

  const renderDivider = (fileIndex: number, isCenterPosition: boolean, paddingLeft?: number, pageIndex?: number): ReactElement => {
    const pageWidth = pageIndex ? pageWidths[getPageIndex(fileIndex, pageIndex)] * scale / 100 : null
    const pagesTotal = pagesInFile[fileIndex]
    const pageWrapperWidth = pageWidths.length ? Math.max(...pageWidths) * scale / 100 : 0

    return (
      <PagesDivider
        filename={getFilename(fileIndex)}
        pageWidth={pageWidth}
        pageIndex={pageIndex}
        pageWrapperWidth={pageWrapperWidth}
        pageTotal={pagesTotal}
        paddingLeft={paddingLeft}
        isCenterPosition={isCenterPosition}
      />
    )
  }

  const renderMarksForm = (formikProps: FormikProps<IMarkFormData>, pageIndex: number, fileIndex: number): ReactElement => {
    const index = getPageIndex(fileIndex, pageIndex)
    const pageScrollTop = canvasWrapperRefArr.current[index]?.offsetTop ?? 0

    const pageSizes = {
      height: pageHeights[index],
      width: pageWidths[index],
    }

    return (
      <MarksForm
        {...formikProps}
        scale={scale / 100}
        fileIndex={fileIndex}
        pageIndex={pageIndex}
        envelopeWrapperRef={envelopeWrapperRef}
        setCurrentSignature={setCurrentSignature}
        pageSizes={pageSizes}
        setPointerTop={setPointerTop}
        setPointerMobileTop={setPointerMobileTop}
        pageScrollTop={pageScrollTop}
      />
    )
  }

  const getPageIndex = (fileIndex: number, pageIndex: number): number => {
    let index = 0
    const files = [...envelope.files].sort((a, b) => a.index > b.index ? 1 : -1)

    files.every(file => {
      if (file.index === fileIndex) {
        index += pageIndex - 1
        return false
      } else {
        index += pagesInFile[file.index]
        return true
      }
    })

    return index
  }

  const addToRefs = (item: HTMLDivElement) => {
    if (item && envelope.files.length === loadedFiles.length && !canvasWrapperRefArr.current.includes(item)) {
      canvasWrapperRefArr.current.push(item)
    }
  }

  const getPrepositionMarks = (isNextTask = false): IPrepositionMarks => {
    const marks = (isNextTask ? getNextTaskRecipient()?.prepositionedMarks : getRecipient()?.prepositionedMarks) ?? []
    const initialMarks = {}
    const recipient = isNextTask ? getNextTaskRecipient() : getRecipient()
    marks.forEach(mark => {
      const markIndex = isNextTask ? mark.key + SECOND_TASK_POSTFIX : mark.key
      switch (mark.type) {
        case MarkType.FULLNAME:
          initialMarks[markIndex] = {
            ...mark,
            value: recipient?.declaredSigner.fullName ?? '',
          }
          break
        case MarkType.POSITION:
          initialMarks[markIndex] = {
            ...mark,
            value: recipient?.declaredSigner.position ?? '',
          }
          break
        case MarkType.INITIALS:
          initialMarks[markIndex] = {
            ...mark,
            value: recipient?.declaredSigner.initials ?? '',
          }
          break
        default:
          initialMarks[markIndex] = {...mark}
      }
      delete initialMarks[markIndex][markIndex]
    })
    return initialMarks
  }

  const handleFormSubmit = ({ marks, nextTaskMarks }: IMarkFormData): void => {
    dispatch(setIsTriedSubmit(true))
    const currentMarks = getIsNextTask() ? nextTaskMarks : marks

    if (currentMarks && !checkSubmitAvailable(currentMarks)) {
      highlightEmplyMark(currentMarks)
      return
    }

    const recipient = getRecipient()
    const nextRecipient = getNextTaskRecipient()
    const isDoubleSigning = getIsDoubleSigning()
    const isNextTask = getIsNextTask()

    const isFirstTaskNoSelfie = recipient?.isRequireSelfie && !selfieGuid
    const isSecondTaskNoSelfie = nextRecipient?.isRequireSelfie && !selfieGuidNextTask

    const isFirstTaskNoSms = recipient?.isRequireSms && !codeValue
    const isSecondTaskNoSms = nextRecipient?.isRequireSms && !codeValueNextTask

    const getIsModalDisplay = (isFirstTaskCondition?: boolean, isSecondTaskCondition?: boolean): boolean => {
      return !!(!isDoubleSigning && isFirstTaskCondition ||
        isDoubleSigning && (isNextTask && isSecondTaskCondition || !isNextTask && isFirstTaskCondition))
    }

    if (modal !== Modal.SELFIE_CONFIRM && getIsModalDisplay(isFirstTaskNoSelfie, isSecondTaskNoSelfie)) {
      dispatch(setModal(Modal.SELFIE_CONFIRM))
      return
    }

    if (getIsModalDisplay(isFirstTaskNoSms, isSecondTaskNoSms)) {
      dispatch(setModal(Modal.PHONE_CONFIRM))
      return
    } else if (taskCounter === 0) dispatch(resetModal())

    if (isDoubleSigning && taskCounter === 0) {
      dispatch(setTaskCounter(1))
      dispatch(setIsTriedSubmit(false))
      return
    }

    /* data filling */
    let submitData: ICompleteTaskData = { geolocation }
    const firstSignerData = getMainSubmitData(marks, signatures)

    if (isDoubleSigning && nextTaskMarks) {
      submitData.signingTask1 = firstSignerData
      submitData.signingTask2 = getMainSubmitData(nextTaskMarks, signaturesNextTask, true)
    } else submitData = Object.assign(submitData, firstSignerData)

    /* sibmit */
    const sharedLinkOguid = getSharedLinkOguid()

    const obj = {sharedLinkOguid: sharedLinkOguid, data: submitData}
    dispatch(isDoubleSigning ? complete2Signers(obj) : completeTask(obj))
      .unwrap()
      .then(() => {
        const redirectUrl = LocalStorageService.getRedirectUrl()
        if (redirectUrl) window.location.href = redirectUrl
        else {
          dispatch(setTaskComplete())
          dispatch(setIsShownFinalMessage(true))
        }
        if (!isNextTask && tranId && codeValue.length ||
            isNextTask && tranIdNextTask && codeValueNextTask.length
        ) dispatch(resetModal())
      })
      .catch((err) => {
        const { message, error } = err
        if (isDoubleSigning && error.includes(invalidCodeMessage)) {
          const signerIndex = error[error.length - 1]
          const signer = signerIndex - 1

          dispatch(resetModal())
          showErrorNotification({
            content: `${message} ${t('envelope:signer.for', {signerIndex})}`,
            title: '',
          })
          dispatch(setTaskCounter(signer))
          signer === 0 ? setCodeValue('') : setCodeValueNextTask('')
        }
        else if (recipient?.isRequireSms) setCodeErrorText(err.message)
        else displayErrorNotification(err)
      })
  }

  const getMainSubmitData = (marks: IPrepositionMarks, signatures: ISignatures, isNext = false): IOneSignerCompleteTaskData => {
    const submitData: IOneSignerCompleteTaskData = { signer: getSubmitSigner(marks) }

    if (signatures.newSignature) submitData.electronicSignatureBase64 = signatures.newSignature
    if (!isNext && selfieGuid) submitData.selfieFileOguid = selfieGuid
    if (isNext && selfieGuidNextTask) submitData.selfieFileOguid = selfieGuidNextTask
    if (Object.values(marks).length) submitData.marks = getSubmitMarks(marks)

    const tran = isNext ? tranIdNextTask : tranId
    const code = isNext ? codeValueNextTask : codeValue
    if ((isNext ? getNextTaskRecipient()?.isRequireSms : getRecipient()?.isRequireSms) && tran) {
      submitData.smsAuthentication = {
        tranId: tran,
        code: code,
      }
    }

    return submitData
  }

  const getSubmitMarks = (marks: IPrepositionMarks): IMark => {
    const submitMarks = {}

    Object.keys(marks)?.forEach(key => {
      const { isRequired, value, type } = marks[key]
      const newKey = key.replace(SECOND_TASK_POSTFIX, '')

      if (!(!isRequired && !value) && value && (type === MarkType.TEXT || type === MarkType.SELECT || type === MarkType.STRING)) {
        submitMarks[newKey] = value
      }
      else if (type === MarkType.CHECKBOX) {
        let checkboxValue: Nullable<string | boolean> = value

        if (value && typeof value !== 'boolean') checkboxValue = !!+value
        submitMarks[newKey] = checkboxValue
      }
    })

    return submitMarks
  }

  const highlightEmplyMark = (marks: IPrepositionMarks): void => {
    const firstRequiredMarkKey = markKeysArray.find(key => (
      marks[key].isRequired ||
      marks[key].type === MarkType.SIGNATURE ||
      marks[key].type === MarkType.FULLNAME
      ))

    if (firstRequiredMarkKey) {
      dispatch(togglePointer())
      dispatch(setCurrentMarkKey(firstRequiredMarkKey))
      dispatch(setHighlightedMarkKey(firstRequiredMarkKey))
    }
  }

  const getSubmitSigner = (marks: IPrepositionMarks): IDeclaredSigner => {
    const fullNameKey = getMarkByType(marks, MarkType.FULLNAME)
    const positionKey = getMarkByType(marks, MarkType.POSITION)
    const initialsKey = getMarkByType(marks, MarkType.INITIALS)

    const declaredSigner = getRecipient()?.declaredSigner
    const signerFullName = declaredSigner?.fullName ?? ''
    const signerPosition = declaredSigner?.position ?? null
    const signerInitials = declaredSigner?.initials ?? null

    const signerSubmit = {
      fullName: fullNameKey && marks[fullNameKey]?.value?.toString() || signerFullName,
    }

    const positionValue = positionKey && marks[positionKey]?.value || signerPosition
    const initialsValue = initialsKey && marks[initialsKey]?.value || signerInitials

    if (positionValue && positionValue.toString().length) signerSubmit['position'] = positionValue
    if (initialsValue && initialsValue.toString().length) signerSubmit['initials'] = initialsValue
    return signerSubmit
  }

  const formData: IMarkFormData = {
    marks: getPrepositionMarks(),
    nextTaskMarks: getIsDoubleSigning() ? getPrepositionMarks(true) : undefined,
  }

  const getIsHorizontalScroll = (isNeedPaggingOffset = false): boolean => {
    const pointerOffset = pointerRef.current?.offsetWidth ?? 0
    const paddingOffset = isNeedPaggingOffset ? 20 : 0

    return !!pageWidths.find(width => width * scale / 100 > window.screen.width - pointerOffset - paddingOffset)
  }

  const getGeolocation = (pos: GeolocationPosition): void => {
    const crd = pos.coords
    setGeolocation(`${crd.latitude};${crd.longitude}`)
  }

  return (
    <div className={cn('relative pt-2 pb-0  scrollbar-layout', styles.content)} ref={contentRef}>
      {envelope.oguid && (
        <div className={canvasWrapperClasses}>
          {taskResult ? renderDocuments() : (
            <Formik validateOnMount initialValues={formData} onSubmit={handleFormSubmit}>
              {renderDocuments}
            </Formik>
          )}
        </div>
      )}
      <InfoSidebar/>
    </div>
  )
}

export default FileViewer
