import React, { FC } from 'react'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from '@hooks'
import EnvelopeActionsButton from '@common/HeaderWrapper/components/EnvelopeActionsButton'
import { setIsOpenedMenu } from '@store/modules/startup'

import styles from './Menu.module.css'

const Menu: FC = () => {
  const dispatch = useAppDispatch()
  const { isOpenedMenu } = useAppSelector(state => state.app)

  const handleCloseMenu = ():void => {
    dispatch(setIsOpenedMenu(false))
  }

  return (
    <div className={cn(styles.menu_wrapper, isOpenedMenu && styles.menu_active)}>
      <div className={styles.darker} onClick={handleCloseMenu}></div>
      <div className={styles.menu_inner}>
        <EnvelopeActionsButton/>
      </div>
    </div>
  )
}

export default Menu
