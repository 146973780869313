import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ILibDropdownItem, LanguageSelector } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import LocalStorageService from '@services/localStorage'
import { LanguageShort, LanguageFull } from '@const/consts'
import { getIsMobile } from '@utils/utils'
import { setIsOpenedMenu } from '@store/modules/startup'
import { ILanguageNames } from './types'

import styles from './LanguageSelectorWrapper.module.css'

const LanguageSelectorWrapper: FC = () => {
  const {
    i18n: {
      changeLanguage,
      language,
      languages,
    },
  } = useTranslation()

  const { isOpenedMenu } = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const [languageNames, setLanguageNames] = useState<Record<string, string>>({})
  const [languageNamesShort, setLanguageNamesShort] = useState<Record<string, string>>({})

  const getLanguageNames = (languages: readonly string[]): ILanguageNames => (
    languages?.reduce(
      (acc, language) => ({
        ...acc,
        languageNamesShort: {
          ...acc.languageNamesShort,
          [language]: (getIsMobile() ? LanguageFull : LanguageShort)[language],
        },
      }),
      {
        languageNames: {},
        languageNamesShort: {},
      },
    )
  )

  const setStateData = (): void => {
    const { languageNames, languageNamesShort } = getLanguageNames(languages)

    setLanguageNames(languageNames)
    setLanguageNamesShort(languageNamesShort)
  }

  useEffect(setStateData, [language])

  const handleLanguageChange = (item: ILibDropdownItem): Promise<void> => {
    const { name } = item

    if (isOpenedMenu) dispatch(setIsOpenedMenu(false))

    return changeLanguage(name).then(() => {
      LocalStorageService.setLanguage(name)
    })
  }

  return (
    <div className={styles.language_selector}>
      <LanguageSelector
        isThemeMini={true}
        language={language}
        languages={languages}
        languageNames={languageNames}
        languageNamesShort={languageNamesShort}
        onLanguageChange={handleLanguageChange}
      />
    </div>
  )
}

export default LanguageSelectorWrapper
