import React, { FC } from 'react'
import cn from 'classnames'

import { ILibOption } from '@infologistics/frontend-libraries'

import { useAppSelector, useAppDispatch } from '@hooks'
import { setCurrentMarkKey } from '@store/modules/shared'
import { checkMarkActivity, selectChangeCheck } from '@utils/envelope'
import { SelectWrapper } from './components/SelectWrapper'
import { ISelectMarkProps as IProps } from './types'

import styles from './SelectMark.module.scss'

const defaultFontSize = 12

const SelectMark: FC<IProps> = ({
  baseStyles,
  classes,
  isNextTaskMark,
  markKey: key,
  touched,
  values,
  width,
  handleBlur,
  setFieldValue,
  setIsSelectOpened,
  renderTooltip,
}) => {
  const { isTriedSubmit, highlightedMarkKey, currentMarkKey } = useAppSelector(state => state.shared)
  const dispatch = useAppDispatch()

  const { marks, nextTaskMarks } = values
  const currentTaskMarks = isNextTaskMark && nextTaskMarks ? nextTaskMarks : marks
  const isInactive = checkMarkActivity(isNextTaskMark)
  const { fontSize, isReadOnly, isRequired, tooltip, value, valueList } = currentTaskMarks[key]

  if (!valueList) return null

  const hasError = (isTriedSubmit || Object.keys(touched).includes(key)) &&
    isRequired && !value

  const selectOptions: ILibOption[] = valueList.map(value => ({
    label: value,
    value,
  }))

  const handleSelectChange = ({ value }: ILibOption, key: string): void => {
    setFieldValue(`${isNextTaskMark ? 'nextTaskMarks' : 'marks'}.${key}.value`, value)
  }

  const selectStyle = {
    lineHeight: `${fontSize && fontSize > defaultFontSize ? fontSize : defaultFontSize + 2}px`,
  }

  const style = Object.assign(baseStyles, selectStyle)

  const handleClick = (): void => {
    dispatch(setCurrentMarkKey(key))
    setIsSelectOpened(true)
  }

  return (
    <div className={cn(styles.select_wrapper, isReadOnly && styles.readonly, isInactive && styles.not_allowed)}>
      <SelectWrapper
        classes={classes}
        commonTextStyles={style}
        controlMinHeight={(defaultFontSize) + 'px'}
        containerWidth={width + 'px'}
        hasError={hasError}
        markKey={key}
        menuIsOpen={currentMarkKey !== key ? false : undefined}
        isFocused={key === highlightedMarkKey}
        isDisabled={isReadOnly || isInactive}
        name={key}
        onBlur={handleBlur}
        onMenuOpen={handleClick}
        onMenuClose={() => setIsSelectOpened(false)}
        onChange={selectChangeCheck((value: ILibOption) => handleSelectChange(value, key))}
        options={selectOptions}
        rawValue={value?.toString()}
        isInactive={isInactive}
      />
      {renderTooltip(tooltip, key)}
    </div>
  )
}

export default SelectMark
