import React, { FC, ReactElement, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import cn from 'classnames'

import { IconRefresh, IconTimes, IconPhoto, Spinner } from '@infologistics/frontend-libraries'

import { useAppDispatch } from '@hooks'
import { Modal } from '@const/consts'
import { getIsMobile } from '@utils/utils'
import { getIsNextTask } from '@utils/envelope'
import { setModal } from '@store/modules/modal'
import { Nullable } from '@store/types'
import { setIsCamOpened, setSelfieImg } from '@store/modules/shared'
import { ICamButton } from './types'

import styles from './WebcamScreen.module.scss'

window.Buffer = require('buffer/').Buffer

const WebcamScreen: FC = () => {
  const dispatch = useAppDispatch()
  const [isFrontCam, setIsFrontCam] = useState(true)
  const webcamRef = useRef<Nullable<Webcam>>(null)

  const videoConstraints = {
    facingMode: isFrontCam ? 'user' : { exact: 'environment' },
  }

  const openSelfieModal = (): void => {
    dispatch(setIsCamOpened(false))
    dispatch(setModal(Modal.SELFIE_CONFIRM))
  }

  const takePhoto = (): void => {
    const imageSrc = webcamRef.current?.getScreenshot()
    const selfieImgData = {
      value: webcamRef.current?.getScreenshot() ?? null,
      isNextTask: getIsNextTask(),
    }

    dispatch(setSelfieImg(selfieImgData))

    if (!imageSrc) return

    openSelfieModal()
  }

  const camButtons: ICamButton[] = [
    {
      name: 'changeDevice',
      icon: <IconRefresh size='md' color='gray-500'/>,
      classes: getIsMobile() ? undefined : styles.webcam_btns_item_disabled,
      action: () => {
        if (getIsMobile()) setIsFrontCam(!isFrontCam)
      },
    },
    {
      name: 'takePhoto',
      icon: <IconPhoto size='md' color='gray-900' classes={styles.webcam_btns_item_main_icon} />,
      classes: styles.webcam_btns_item_main,
      action: takePhoto,
    },
    {
      name: 'closeCam',
      icon: <IconTimes size='md' color='gray-500'/>,
      action: openSelfieModal,
    },
  ]

  const renderCamButton = (btn: ICamButton): ReactElement => (
    <div
      key={btn.name}
      className={cn(styles.webcam_btns_item, btn.classes)}
      onClick={btn.action}
    >
      {btn.icon}
    </div>
  )

  return (
    <div className={styles.webcam_wrapper}>
      <div className={styles.webcam_spinner_wrapper}>
        <div className={styles.webcam_spinner_inner}>
          <Spinner/>
        </div>
      </div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        className={styles.webcam}
        mirrored={isFrontCam}
        videoConstraints={videoConstraints}
        screenshotQuality={1}
      />
      <div className={styles.webcam_btns}>
        {camButtons.map(btn => renderCamButton(btn))}
      </div>
    </div>
  )
}

export default WebcamScreen
