import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import SignatureCanvas from 'react-signature-canvas'
import cn from 'classnames'

import { Button, Nullable } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { getIsMobile } from '@utils/utils'
import { setDrawSignatureFull, setDrawSignatureTrim } from '@store/modules/shared'
import { ISignatureValue } from '../types'

import styles from '../SignatureModal.module.scss'
import { getIsNextTask } from '@app/utils/envelope'

const ProfileSignatureDraw: FC<FormikProps<ISignatureValue>> = ({ setFieldValue }) => {
  const { t } = useTranslation('envelope')
  const dispatch = useAppDispatch()
  const { signatures, signaturesNextTask } = useAppSelector(state => state.shared)
  const [sigPad, setSigPad] = useState<Nullable<SignatureCanvas>>(null)

  const isNextTask = getIsNextTask()
  const { drawSignatureFull, drawSignatureTrim, uploadSignature } = isNextTask ? signaturesNextTask : signatures
  const canvasSizes = getIsMobile() ? { height: 216, width: 256 } : { height: 320 }

  useEffect(() => {
    drawSignatureFull && sigPad &&
      sigPad.fromDataURL(`data:image/png;base64,${drawSignatureFull}`)
  }, [sigPad])

  useEffect(() => {
    drawSignatureTrim && setFieldValue('newSignature', drawSignatureTrim)
  }, [])

  const handleSignatureDrawEnd = () => {
    if (!sigPad) return

    const getSignatureBase64 = (canvas: HTMLCanvasElement): string => canvas.toDataURL().split(';base64,')[1]

    const drawSignatureFull = getSignatureBase64(sigPad.getCanvas())
    const drawSignatureTrim = getSignatureBase64(sigPad.getTrimmedCanvas())

    if (drawSignatureFull) {
      dispatch(setDrawSignatureFull({ value: drawSignatureFull, isNextTask }))
    }

    if (drawSignatureTrim) {
      dispatch(setDrawSignatureTrim({ value: drawSignatureTrim, isNextTask }))
      setFieldValue('newSignature', drawSignatureTrim)
    }
  }

  const handleClearButtonClick = (): void => {
    sigPad?.clear()
    const emptySignatureData = { value: null, isNextTask }

    dispatch(setDrawSignatureFull(emptySignatureData))
    dispatch(setDrawSignatureTrim(emptySignatureData))
    setFieldValue('newSignature', uploadSignature)
  }

  return (
    <div className={cn('d-flex flex-column full-width relative', styles.canvas_wrapper)}>
      <SignatureCanvas
        ref={ref => setSigPad(ref)}
        canvasProps={{ ...canvasSizes, className: styles.signature_draw  }}
        penColor='#3276B1'
        onEnd={handleSignatureDrawEnd}
      />
      {!getIsMobile() && <p className='text-center text-muted mt-2 mb-0'>
        { t('envelope:signature.drawDigitalSignature') }
      </p>}
      <Button
        theme='link'
        linkStyle='none'
        onClick={handleClearButtonClick}
        classes={cn(styles.canvas_clear_btn)}
      >
        { t('envelope:signature.clear') }
      </Button>
    </div>
  );
}

export default ProfileSignatureDraw
