import React, { FC, ReactElement, useState } from 'react'
import cn from 'classnames'

import { Alert, IconUserScreen } from '@infologistics/frontend-libraries'

import { useAppSelector } from '@hooks'
import { getIsNextTask } from '@utils/envelope'
import { Nullable } from '@store/types'
import SelfieConfirmModalWrapper from './components/SelfieConfirmModalWrapper'
import { ISelfieConfirmModalProps as IProps } from './types'

import styles from './SelfieConfirmModal.module.scss'

const SelfieConfirmModal: FC<IProps> = (props) => {
  const { selfieImg, selfieImgNextTask } = useAppSelector(state => state.shared)
  const [errorText, setErrorText] = useState('')
  const contentClasses = cn(
    styles.selfie_content,
    !!errorText?.length && styles.selfie_content_error,
    'd-flex justify-content-center align-items-center',
  )

  const renderContent = (selfieImg: Nullable<string>): ReactElement => {
    return (selfieImg
      ? <img src={selfieImg} alt='user selfie' className={styles.selfie_content_img} />
      : <IconUserScreen classes={styles.selfie_content_icon} />
    )
  }

  return (
    <SelfieConfirmModalWrapper setErrorText={setErrorText} {...props}>
      {!!errorText?.length && <Alert title={errorText} theme='danger' classes={cn(styles.alert, 'mb-2')}/>}
      <div className={contentClasses}>
        {renderContent(getIsNextTask() ? selfieImgNextTask : selfieImg)}
      </div>
    </SelfieConfirmModalWrapper>
  )
}

export default SelfieConfirmModal
