import React, { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { resetModal } from '@store/modules/modal'
import { getIsMobile } from '@utils/utils'
import { IModalWrapperProps as IProps } from '../types'

import styles from '../SignatureModal.module.scss'

const SignatureModalWrapper: FC<IProps> = ({ children, hasSignature }) => {
  const { t } = useTranslation(['envelope', 'common'])
  const { drawSignatureTrim, uploadSignature } = useAppSelector(state => state.shared.signatures)
  const dispatch = useAppDispatch()

  return (
    <Fragment>
      <header className='p-4 modal-header'>
        <h4 className='mb-0'>
          { t('envelope:signature.title') }
        </h4>
      </header>
      <div className='p-4 font-xs modal-body'>
        { children }
      </div>
      <footer className='my-0 p-4 modal-footer'>
        {!getIsMobile() && <p className='text-muted font-xs m-0 mb-2'>{t('envelope:signature.infoMessage')}</p>}
        <div className={styles.modal_btns_wrapper}>
          <Button theme='primary' type='submit' isDisabled={hasSignature && !(drawSignatureTrim || uploadSignature)}>
            {t('common:save')}
          </Button>
          <Button onClick={() => dispatch(resetModal())}>
            {t('common:cancel')}
          </Button>
        </div>
      </footer>
    </Fragment>
  )
}

export default SignatureModalWrapper
