import React, { FC, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
  DashedLine,
  Ellipsis,
  IconActions,
  IconCheck,
  IconCircleCheck,
  IconInProgress,
  IconStateApproving,
  IconStateReceiving,
  IconStateSigning,
  IconTimesCircle,
  IconWaiting,
  ILibHorizontalPosition,
  Popover,
  SidebarContent,
} from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks'
import { Nullable } from '@store/types'
import { setIsOpenedEnvelopeInfo } from '@store/modules/startup'
import { IRecipient, ITask } from '@store/modules/shared/types'
import { getDate } from '@utils/utils'
import { State, TaskResult, TaskType } from '@const/consts'
import { IStatusItem } from './types'

import styles from './InfoSidebar.module.scss'

const InfoSidebar: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { envelope } = useAppSelector(state => state.shared)
  const { isOpenedEnvelopeInfo } = useAppSelector(state => state.app)

  const { flow, flowStartDateTime, sender, subject } = envelope

  const renderMainInfo = (): ReactElement => {
    const { boxEmail, user } = sender

    return (
      <div className={cn(styles.info_block, 'py-4')}>
        <p className={cn(styles.subject, 'mt-0 mb-2')}>
          {t('envelope:info.forSignature')}: {subject}
        </p>
        <div className={cn(styles.info_container, 'font-sm')}>
          <div className={cn(styles.info_column,'text-muted')}>
            <p>{t('envelope:info.sent')}</p>
            <p>{getDate(flowStartDateTime)}</p>
          </div>
          <div className={cn(styles.info_column)}>
            <p className='fw-600' title={boxEmail}>
              <Ellipsis>{boxEmail}</Ellipsis>
            </p>
            <p title={user.fullName}>
              <Ellipsis>{user.fullName}</Ellipsis>
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderTasks = (): ReactElement => {
    const tasks: ITask[] = []
    flow.forEach(stage => stage.tasks.forEach(task => tasks.push(task)))

    return (
      <div className={cn(styles.tasks_wrapper, 'overflow-y-scroll full-width')}>
        {tasks.map(renderTask)}
      </div>
    )
  }

  const renderTask = (task: ITask, index: number): ReactElement => {
    const { completedDateTime, recipient, result, state, type } = task
    const { declaredSigner, email } = recipient

    return (
      <div key={index} className={cn(styles.info_block, styles.info_container, 'py-3 font-sm')}>
        <div className={styles.info_column}>
          {renderState(state, result)}
          {renderType(type)}
        </div>
        <div className={cn(styles.info_column, 'd-flex')}>
          <div className={styles.declared_signer}>
            <p className='fw-600 mb-1' title={email}>
              <Ellipsis>{email}</Ellipsis>
            </p>
            <p className='text-muted font-xs' title={declaredSigner.fullName}>
              <Ellipsis>{declaredSigner.fullName}</Ellipsis>
            </p>
            {completedDateTime && <p className='text-muted font-xs'>{getDate(completedDateTime)}</p>}
          </div>
          {renderAdditionalInfo(recipient)}
        </div>
      </div>
    )
  }

  const renderAdditionalInfo = (recipient: IRecipient): ReactElement => {
    const { declaredSigner, isHostMode, isRequireSelfie, isRequireSms } = recipient
    const { position, initials, phone } = declaredSigner

    const additionalInfoFields = {
      position,
      initials,
      phone,
      isHostMode,
      isRequireSelfie,
      isRequireSms,
    }

    const isAllowed = !!Object.values(additionalInfoFields).find(value => value)

    return (!isAllowed ? renderPopoverButton(isAllowed) : (
      <Popover
        buttonComponent={renderPopoverButton(isAllowed)}
        buttonClasses='pointer'
        containerClasses={styles.popover_container}
        horizontalPosition={ILibHorizontalPosition.RIGHT}
        withoutArrow
      >
        <div className={cn('p-2', styles.additional_info)}>
          {Object.keys(additionalInfoFields).map(key => renderAdditionalInfoItem(additionalInfoFields[key], key, key.includes('is')))}
        </div>
        <span className={cn('fl-popover-arrow', styles.popover_arrow)} />
      </Popover>
      )
    )
  }

  const renderAdditionalInfoItem = (text: Nullable<string>, label: string, isBoolean: boolean): ReactNode => {
    const textContent = <>
      <span className='text-muted mr-2'>{t(`envelope:info.fields.${label}`)}:</span>
      {isBoolean ? text && <IconCheck size='sm'/> : text}
    </>

    return !text ? null : (
      <p key={label} title={isBoolean ? undefined : text} className='d-flex'>
        {isBoolean ? textContent : <Ellipsis>{textContent}</Ellipsis>}
      </p>
    )
  }

  const renderPopoverButton = (isAllowed: boolean): ReactElement => {
    const popoverButtonColor = isAllowed ? 'gray-500' : 'gray-200'
    const popoverButtonClasses = cn(!isAllowed && styles.not_allowed, 'mt-n1')

    return <IconActions rotation='90' size='md' color={popoverButtonColor} classes={popoverButtonClasses}/>
  }

  const renderState = (taskState: State, result: Nullable<TaskResult>): ReactNode => {
    const states: IStatusItem[] = [
      {
        name: State.COMPLETED,
        icon: <IconCircleCheck size='sm'/>,
        color: 'text-success',
      },
      {
        name: State.IN_PROGRESS,
        icon: <IconInProgress size='sm'/>,
        color: 'text-primary',
      },
      {
        name: State.WAITING,
        icon: <IconWaiting size='sm'/>,
        color: 'text-muted',
      },
      {
        name: State.CANCELED,
        icon: null,
        color: 'text-muted',
      },
    ]

    const declinedState: IStatusItem = {
      name: TaskResult.DECLINE,
      icon: <IconTimesCircle size='sm'/>,
      color: 'text-danger',
    }

    const canceledResults = [TaskResult.CANCELED, TaskResult.EXPIRED, null]
    const isCanceledState = taskState === State.COMPLETED && canceledResults.includes(result)
    const canceledState = states.find(state => state.name === State.CANCELED)
    const state = isCanceledState
      ? canceledState
      : (result === TaskResult.DECLINE ? declinedState : states.find(state => state.name === taskState))

    if (!state) return null

    const { color, icon, name } = state

    return (
      <p className={cn(color, 'd-flex mb-1')}>
        {icon && <span className='mr-2'>{icon}</span>}
        {t(`envelope:info.states.${name.toLowerCase()}`)}
      </p>
    )
  }

  const renderType = (taskType: TaskType): ReactNode => {
    const types: IStatusItem[] = [
      {
        name: TaskType.SIGNING,
        icon: <IconStateSigning size='sm' color='gray-500'/>,
      },
      {
        name: TaskType.APPROVAL,
        icon: <IconStateApproving size='sm' color='gray-500'/>,
      },
      {
        name: TaskType.ACQUAINTANCE,
        icon: <IconStateReceiving size='sm' color='gray-500'/>,
      },
    ]

    const type = types.find(type => type.name === taskType )
    if (!type) return null

    const {icon, name } = type

    return (
      <p className='d-flex fw-600'>
        {icon && <span className='mr-2'>{icon}</span>}
        {t(`envelope:info.types.${name.toLowerCase()}`)}
      </p>
    )
  }

  const sidebarClose = () => dispatch(setIsOpenedEnvelopeInfo(!isOpenedEnvelopeInfo))

  return (
    <SidebarContent
      externalClass={cn(styles.sidebar, 'px-3 pt-2')}
      isOpen={isOpenedEnvelopeInfo}
      closeText={t('common:close')}
      onClose={sidebarClose}
    >
      <header className='full-width'>
        <h2 className='mb-2'>{t('envelope:info.title')}</h2>
        <DashedLine />
      </header>
      {renderMainInfo()}
      {renderTasks()}
    </SidebarContent>
  )
}

export default InfoSidebar
